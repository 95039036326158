import React, { useState, useEffect } from 'react';
import ToggleSwitch1 from '../../general-components/ToggleSwitch1.component';
import CustomButton7 from '../../general-components/CustomButton7.component';
import CustomButton6 from '../../general-components/CustomButton6.component';
import ToggleSwitch2 from '../../general-components/ToggleSwitch2.component';
import { IoIosArrowDown } from "react-icons/io";
import { GrFormNext } from "react-icons/gr";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AccessControl({ onCancel, selectedAccess, selectedCId, selectedRlId }) {
    const [checkboxAdminRead, setCheckboxAdminRead] = useState(0);
    const [checkboxAdminCreate, setCheckboxAdminCreate] = useState(0);
    const [checkboxAdminUpdate, setCheckboxAdminUpdate] = useState(0);

    const [checkboxHierarchyRead, setCheckboxHierarchyRead] = useState(0);
    const [checkboxHierarchyCreate, setCheckboxHierarchyCreate] = useState(0);
    const [checkboxHierarchyUpdate, setCheckboxHierarchyUpdate] = useState(0);

    const [checkboxEmployeeRead, setCheckboxEmployeeRead] = useState(0);
    const [checkboxEmployeeCreate, setCheckboxEmployeeCreate] = useState(0);
    const [checkboxEmployeeUpdate, setCheckboxEmployeeUpdate] = useState(0);

    const [checkboxDashboardRead, setCheckboxDashboardRead] = useState(0);
    const [checkboxDashboardCreate, setCheckboxDashboardCreate] = useState(0);
    const [checkboxDashboardUpdate, setCheckboxDashboardUpdate] = useState(0);

    const [checkboxFeedbackRead, setCheckboxFeedbackRead] = useState(0);
    const [checkboxFeedbackCreate, setCheckboxFeedbackCreate] = useState(0);
    const [checkboxFeedbackUpdate, setCheckboxFeedbackUpdate] = useState(0);

    const [checkboxManageFeedbackRead, setCheckboxManageFeedbackRead] = useState(0);
    const [checkboxManageFeedbackCreate, setCheckboxManageFeedbackCreate] = useState(0);
    const [checkboxManageFeedbackUpdate, setCheckboxManageFeedbackUpdate] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [isOpen1, setIsOpen1] = useState(true);
    const [isOpen2, setIsOpen2] = useState(true);
    const [isOpen3, setIsOpen3] = useState(true);

    const [main1, setMain1] = useState(0);
    const [main2, setMain2] = useState(0);
    const [main3, setMain3] = useState(0);

    const toggleAccordion1 = () => {
        setIsOpen1(!isOpen1);
    };

    const toggleAccordion2 = () => {
        setIsOpen2(!isOpen2);
    };

    const toggleAccordion3 = () => {
        setIsOpen3(!isOpen3);
    };

    useEffect(() => {
        if (selectedAccess) {
            selectedAccess.forEach(access => {
                switch (access.main_menu_name) {
                    case "Administrator":
                        setMain1(access.hideInMenu ? 0 : 1)
                        access.routes.forEach(route => {
                            if (route.sub_menu_name === "Role") {
                                setCheckboxAdminRead(route.custPermissions.canRead ? 1 : 0);
                                setCheckboxAdminCreate(route.custPermissions.canCreate ? 1 : 0);
                                setCheckboxAdminUpdate(route.custPermissions.canUpdate ? 1 : 0);
                            } else if (route.sub_menu_name === "Role Hierarchy") {
                                setCheckboxHierarchyRead(route.custPermissions.canRead ? 1 : 0);
                                setCheckboxHierarchyCreate(route.custPermissions.canCreate ? 1 : 0);
                                setCheckboxHierarchyUpdate(route.custPermissions.canUpdate ? 1 : 0);
                            } else if (route.sub_menu_name === "Employee") {
                                setCheckboxEmployeeRead(route.custPermissions.canRead ? 1 : 0);
                                setCheckboxEmployeeCreate(route.custPermissions.canCreate ? 1 : 0);
                                setCheckboxEmployeeUpdate(route.custPermissions.canUpdate ? 1 : 0);
                            }
                        });
                        break;
                    case "Dashboard":
                        setMain2(access.hideInMenu ? 0 : 1)
                        access.routes.forEach(route => {
                            setCheckboxDashboardRead(route.custPermissions.canRead ? 1 : 0);
                            setCheckboxDashboardCreate(route.custPermissions.canCreate ? 1 : 0);
                            setCheckboxDashboardUpdate(route.custPermissions.canUpdate ? 1 : 0);
                        });
                        break;
                    case "Feedback":
                        setMain3(access.hideInMenu ? 0 : 1)
                        access.routes.forEach(route => {
                            if (route.sub_menu_name === "Feedback Category") {
                                setCheckboxFeedbackRead(route.custPermissions.canRead ? 1 : 0);
                                setCheckboxFeedbackCreate(route.custPermissions.canCreate ? 1 : 0);
                                setCheckboxFeedbackUpdate(route.custPermissions.canUpdate ? 1 : 0);
                            } else if (route.sub_menu_name === "Manage Feedback") {
                                setCheckboxManageFeedbackRead(route.custPermissions.canRead ? 1 : 0);
                                setCheckboxManageFeedbackCreate(route.custPermissions.canCreate ? 1 : 0);
                                setCheckboxManageFeedbackUpdate(route.custPermissions.canUpdate ? 1 : 0);
                            }
                        });
                        break;
                    default:
                        break;
                }
            });
        }
    }, [selectedAccess]);

    console.log("fgfhgh--->", selectedAccess);

    const handleSubmit = async () => {
        setIsLoading(true);
        const token = localStorage.getItem('ASARFI_CRM_authToken');

        // Dynamically build the routes data
        const routes = [
            {
                "path": "/administrator",
                "main_menu_name": "Administrator",
                "cust_key": "mm_administrator",
                "sub_heading": "Manage Users",
                "isChildren": true,
                "hideInMenu": main1 === 1,
                "hideChildrenInMenu": false,
                "useForAccess": true,
                "hasPermissions": false,
                "routes": [
                    {
                        "path": "/administrator/manage-users",
                        "sub_menu_name": "Role",
                        "cust_key": "mm_administrator_manage_roles",
                        "hideInMenu": checkboxAdminRead === 1,
                        "hideChildrenInMenu": false,
                        "useForAccess": true,
                        "hasPermissions": true,
                        "custPermissions": {
                            "canRead": checkboxAdminRead === 1,
                            "canCreate": checkboxAdminCreate === 1,
                            "canUpdate": checkboxAdminUpdate === 1
                        },
                        "sub_sub_menu": {
                            "canRead": true,
                            "canCreate": true,
                            "canUpdate": true
                        }
                    },
                    {
                        "path": "/administrator/manage-users",
                        "sub_menu_name": "Role Hierarchy",
                        "cust_key": "mm_administrator_manage_role_hierarchy",
                        "hideInMenu": checkboxHierarchyRead === 1,
                        "hideChildrenInMenu": false,
                        "useForAccess": true,
                        "hasPermissions": true,
                        "custPermissions": {
                            "canRead": checkboxHierarchyRead === 1,
                            "canCreate": checkboxHierarchyCreate === 1,
                            "canUpdate": checkboxHierarchyUpdate === 1
                        },
                        "sub_sub_menu": {
                            "canRead": true,
                            "canCreate": true,
                            "canUpdate": true
                        }
                    },
                    {
                        "path": "/administrator/manage-users",
                        "sub_menu_name": "Employee",
                        "cust_key": "mm_administrator_manage_employees",
                        "hideInMenu": checkboxEmployeeRead === 1,
                        "hideChildrenInMenu": false,
                        "useForAccess": true,
                        "hasPermissions": true,
                        "custPermissions": {
                            "canRead": checkboxEmployeeRead === 1,
                            "canCreate": checkboxEmployeeCreate === 1,
                            "canUpdate": checkboxEmployeeUpdate === 1
                        },
                        "sub_sub_menu": {
                            "canRead": true,
                            "canCreate": true,
                            "canUpdate": true
                        }
                    }
                ]
            },
            {
                "path": "/dashboard",
                "main_menu_name": "Dashboard",
                "cust_key": "mm_dashboard",
                "isChildren": true,
                "hideInMenu": main2 === 1,
                "hideChildrenInMenu": false,
                "useForAccess": true,
                "hasPermissions": false,
                "routes": [
                    {
                        "path": "/dashboard/manage-dashboard",
                        "sub_menu_name": "Dashboard",
                        "cust_key": "mm_manage_dashboard",
                        "hideInMenu": checkboxDashboardRead === 1,
                        "hideChildrenInMenu": false,
                        "useForAccess": true,
                        "hasPermissions": true,
                        "custPermissions": {
                            "canRead": checkboxDashboardRead === 1,
                            "canCreate": checkboxDashboardCreate === 1,
                            "canUpdate": checkboxDashboardUpdate === 1
                        }
                    }
                ]
            },
            {
                "path": "/feedback",
                "main_menu_name": "Feedback",
                "cust_key": "mm_feedback",
                "isChildren": true,
                "hideInMenu": main3 === 1,
                "hideChildrenInMenu": false,
                "useForAccess": true,
                "hasPermissions": false,
                "routes": [
                    {
                        "path": "/feedback/manage-feedback",
                        "sub_menu_name": "Feedback Category",
                        "cust_key": "mm_endusers_manage_feedback_categories",
                        "hideInMenu": checkboxFeedbackRead === 1,
                        "hideChildrenInMenu": false,
                        "useForAccess": true,
                        "hasPermissions": true,
                        "custPermissions": {
                            "canRead": checkboxFeedbackRead === 1,
                            "canCreate": checkboxFeedbackCreate === 1,
                            "canUpdate": checkboxFeedbackUpdate === 1
                        }
                    },
                    {
                        "path": "/feedback/manage-feedback/crud/read",
                        "sub_menu_name": "Manage Feedback",
                        "cust_key": "mm_endusers_manage_feedback",
                        "hideInMenu": checkboxManageFeedbackRead === 1 ? false : true,
                        "hideChildrenInMenu": false,
                        "useForAccess": true,
                        "hasPermissions": true,
                        "custPermissions": {
                            "canRead": checkboxManageFeedbackRead === 1,
                            "canCreate": checkboxManageFeedbackCreate === 1 ,
                            "canUpdate": checkboxManageFeedbackUpdate === 1 
                        }
                    }
                ]
            }
        ];

        const payload = {
            _method: 'put',
            rl_id: selectedRlId,
            c_id: selectedCId,
            routes: JSON.stringify(routes)
        };
        console.log("api-payload--->", payload);

        const url = `${process.env.REACT_APP_MAIN_URL}/manageendusers/role/update_role_route`;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            const responseJson = await response.json();

            if (response.ok) {
                console.log("ssdfsdf--->",responseJson);
                if (responseJson === 202) {
                    toast.success('Record updated');
                    setTimeout(() => {
                        onCancel();
                    }, 1000);
                } else {
                    toast.error('Record operation failed');
                }
            } else {
                console.error('Record operation failed:', responseJson);
                toast.error('Record operation failed');
            }
        } catch (error) {
            console.error('Error performing Manage Access operation:', error);
            toast.error('Error performing Manage Access operation');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-5 bg-white rounded-lg">
                <div className="flex bg-white p-4 text-black justify-between rounded-t-lg border-b">
                    <h1 className="text-xl font-weight-[400]">Edit Role Access</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <form >
                    <div className="p-4 gap-4 max-h-[50vh] overflow-y-auto max-w-ful w-[700px] border-b">

                        <div className="p-2 ">
                            <div
                                className="flex p-2 border-b-2 justify-between border border-gray-300 bg-gray-100 cursor-pointer"
                            >
                                <div className='flex gap-1 items-center' onClick={toggleAccordion1}>
                                    {isOpen1 ? <IoIosArrowDown className='text-[1.1rem]' /> : <GrFormNext className=' text-[1.3rem]' />}
                                    <h1 className="text-l font-weight-[400] ">Administrator</h1>
                                </div>
                                <div>
                                    <ToggleSwitch2
                                        state={main1}
                                        setState={setMain1}
                                    />
                                </div>
                            </div>
                            {isOpen1 && (
                                <div className='p-2 border border-gray-300'>
                                    <div className="text-lg">Manage Users</div>
                                    <div className="text-gray-500 text-md mt-2">Role</div>
                                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mt-1">
                                        <ToggleSwitch1
                                            label={"Read :"}
                                            state={checkboxAdminRead}
                                            setState={setCheckboxAdminRead}
                                        />
                                        <ToggleSwitch1
                                            label={"Create :"}
                                            state={checkboxAdminCreate}
                                            setState={setCheckboxAdminCreate}
                                        />
                                        <ToggleSwitch1
                                            label={"Update :"}
                                            state={checkboxAdminUpdate}
                                            setState={setCheckboxAdminUpdate}
                                        />
                                    </div>

                                    <div className="text-gray-500 text-md mt-4">Role Hierarchy</div>
                                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mt-1">
                                        <ToggleSwitch1
                                            label={"Read :"}
                                            state={checkboxHierarchyRead}
                                            setState={setCheckboxHierarchyRead}
                                        />
                                        <ToggleSwitch1
                                            label={"Create :"}
                                            state={checkboxHierarchyCreate}
                                            setState={setCheckboxHierarchyCreate}
                                        />
                                        <ToggleSwitch1
                                            label={"Update :"}
                                            state={checkboxHierarchyUpdate}
                                            setState={setCheckboxHierarchyUpdate}
                                        />
                                    </div>

                                    <div className="text-gray-500 text-md mt-4">Employee</div>
                                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mt-1">
                                        <ToggleSwitch1
                                            label={"Read :"}
                                            state={checkboxEmployeeRead}
                                            setState={setCheckboxEmployeeRead}
                                        />
                                        <ToggleSwitch1
                                            label={"Create :"}
                                            state={checkboxEmployeeCreate}
                                            setState={setCheckboxEmployeeCreate}
                                        />
                                        <ToggleSwitch1
                                            label={"Update :"}
                                            state={checkboxEmployeeUpdate}
                                            setState={setCheckboxEmployeeUpdate}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="p-2 ">
                            <div
                                className="flex p-2 border-b-2 justify-between border border-gray-300 bg-gray-100 cursor-pointer"
                            >
                                <div className='flex gap-1 items-center' onClick={toggleAccordion2}>
                                    {isOpen2 ? <IoIosArrowDown className='text-[1.1rem]' /> : <GrFormNext className=' text-[1.3rem]' />}
                                    <h1 className="text-l font-weight-[400] ">Dashboard</h1>
                                </div>
                                <div>
                                    <ToggleSwitch2
                                        state={main2}
                                        setState={setMain2}
                                    />
                                </div>
                            </div>
                            {isOpen2 && (
                                <div className='p-2 border border-gray-300'>
                                    <div className="text-lg">Dashboard</div>
                                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mt-1">
                                        <ToggleSwitch1
                                            label={"Read :"}
                                            state={checkboxDashboardRead}
                                            setState={setCheckboxDashboardRead}
                                        />
                                        <ToggleSwitch1
                                            label={"Create :"}
                                            state={checkboxDashboardCreate}
                                            setState={setCheckboxDashboardCreate}
                                        />
                                        <ToggleSwitch1
                                            label={"Update :"}
                                            state={checkboxDashboardUpdate}
                                            setState={setCheckboxDashboardUpdate}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="p-2 ">
                            <div
                                className="flex p-2 border-b-2 justify-between border border-gray-300 bg-gray-100 cursor-pointer"
                            >
                                <div className='flex gap-1 items-center' onClick={toggleAccordion3}>
                                    {isOpen3 ? <IoIosArrowDown className='text-[1.1rem]' /> : <GrFormNext className=' text-[1.3rem]' />}
                                    <h1 className="text-l font-weight-[400] ">Feedback</h1>
                                </div>
                                <div>
                                    <ToggleSwitch2
                                        state={main3}
                                        setState={setMain3}
                                    />
                                </div>
                            </div>
                            {isOpen3 && (
                                <div className='p-2 border border-gray-300'>
                                    <div className="text-lg">Feedback Category</div>
                                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mt-1">
                                        <ToggleSwitch1
                                            label={"Read :"}
                                            state={checkboxFeedbackRead}
                                            setState={setCheckboxFeedbackRead}
                                        />
                                        <ToggleSwitch1
                                            label={"Create :"}
                                            state={checkboxFeedbackCreate}
                                            setState={setCheckboxFeedbackCreate}
                                        />
                                        <ToggleSwitch1
                                            label={"Update :"}
                                            state={checkboxFeedbackUpdate}
                                            setState={setCheckboxFeedbackUpdate}
                                        />
                                    </div>

                                    <div className="text-lg mt-4">Manage Feedback</div>
                                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mt-1">
                                        <ToggleSwitch1
                                            label={"Read :"}
                                            state={checkboxManageFeedbackRead}
                                            setState={setCheckboxManageFeedbackRead}
                                        />
                                        <ToggleSwitch1
                                            label={"Create :"}
                                            state={checkboxManageFeedbackCreate}
                                            setState={setCheckboxManageFeedbackCreate}
                                        />
                                        <ToggleSwitch1
                                            label={"Update :"}
                                            state={checkboxManageFeedbackUpdate}
                                            setState={setCheckboxManageFeedbackUpdate}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-end gap-5 pt-5 px-5">
                        <div onClick={onCancel}>
                            <CustomButton7 label={"Cancel"} variant="outlined" className="hover:text-red-500 text-black" />
                        </div>
                        <div onClick={handleSubmit}>
                            <CustomButton6 label={"Update"} className="text-white bg-first" />
                        </div>
                    </div>
                </form>

            </div>

            <ToastContainer autoClose={2000} />
        </>
    );
}

import React, { useState } from 'react';

import CustomButton1 from '../../general-components/CustomButton1.component';
import CustomButton2 from '../../general-components/CustomButton2.component';
import ToolTipY from '../../general-components/ToolTipY.component';

import { BsFillExclamationCircleFill } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { AiOutlineEnter } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";

import imgf1 from '../../../assets/f1.png';
import imgf2 from '../../../assets/f2.png';
import imgf3 from '../../../assets/f3.png';
import "../../../../index.css";

export default function EmojiQuestion({ id, index, onDelete, onQuestionChange }) {

    const [isEditingQuestion, setIsEditingQuestion] = useState(false);
    const [questionText, setQuestionText] = useState('Type Question');

    const handleEditQuestionClick = () => setIsEditingQuestion(true);
    const handleQuestionChange = (event) => {
        setQuestionText(event.target.value);
        onQuestionChange(id, questionText);
    };

    return (
        <div className='py-5 border-b border-gray-300'>
            <div className='flex flex-row items-center w-full'>
                <div className="px-6 text-black w-[70%]">
                    <div className='flex items-center gap-1 text-black'>
                        <span>{index + 1}.</span>
                        {isEditingQuestion ? (
                            <div className='border border-red-500 w-full py-1 px-2 flex justify-between items-center'>
                                <input
                                    type='text'
                                    value={questionText}
                                    onChange={handleQuestionChange}
                                    className='text-sm font-light w-full'
                                    onBlur={() => setIsEditingQuestion(false)} // Automatically stop editing on blur
                                    autoFocus
                                />
                                <span className='text-gray-500'><AiOutlineEnter /></span>
                            </div>
                        ) : (
                            <>
                                <span>{questionText}</span>
                                <span className='text-red-500 cursor-pointer' onClick={handleEditQuestionClick}><CiEdit /></span>
                            </>
                        )}
                    </div>
                </div>
                <div className="px-6 w-[15%]">
                    <div className='border border-dashed border-gray-500 px-2 py-1 text-center'>
                        Emoji rating
                    </div>
                </div>
                <div className='py-4 px-6 w-[15%]'>
                    <ToolTipY
                        title={
                            <div className=" text-red-500 text-2xl">
                                <MdDeleteOutline />
                            </div>
                        }
                        content={
                            <>
                                <div className="flex flex-col gap-2 p-2 overflow-hidden rounded whitespace-nowrap">
                                    <div className="py-1 px-2 flex justify-start items-center gap-2">
                                        <span className='text-yellow-500'> <BsFillExclamationCircleFill /></span>
                                        Are You Sure to Delete ?
                                    </div>
                                    <div className="flex justify-center gap-2">
                                        <div>
                                            <CustomButton2 label={"No"} variant="outlined" className="text-red-500 text-xs" />
                                        </div>
                                        <div onClick={() => onDelete(id)} >
                                            <CustomButton1 label={"Yes"} className="text-white bg-red-500 text-xs" />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        height="80px"
                        direction="down"
                        position="center"
                    ></ToolTipY>
                </div>
            </div>
            <div className='pt-3 px-6'>
                <div className='flex gap-2'>
                    <img src={imgf1} className='w-[4.5rem] h-[4.5rem]' alt="emoji1" />
                    <img src={imgf2} className='w-[4.5rem] h-[4.5rem]' alt="emoji2" />
                    <img src={imgf3} className='w-[4.5rem] h-[4.5rem]' alt="emoji3" />
                </div>
            </div>
        </div>
    );
}

import React, { useState } from 'react'
import CustomButton2 from '../../general-components/CustomButton2.component'
import CustomButton1 from '../../general-components/CustomButton1.component'
import { BsFillExclamationCircleFill } from 'react-icons/bs'

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function FeedBackStatus({ onCancel, selectedItem }) {

    const token = localStorage.getItem('ASARFI_CRM_authToken');
    const [isLoading, setIsLoading] = useState(false);

    const handleStatusChange = async (fdq_id, action) => {
        setIsLoading(true);

        const form = new FormData();
        form.append('_method', 'put');
        form.append('fdq_id', fdq_id);

        const url = `${process.env.REACT_APP_MAIN_URL}/manageendusers/feedback/feedback_crud/status-${action}`;

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: form
            });

            const statusCode = await response.json();

            if (response.ok) {
                if (statusCode === 202) {
                    toast.success('Record updated');
                    // setPopupStatus(false);
                    setTimeout(() => {
                        onCancel();
                    }, 1000);
                } else {
                    toast.error('Record operation failed');
                }
            } else {
                console.error('Record operation failed');
                toast.error('Record operation failed');
            }
        } catch (error) {
            console.error('Error performing Feedback operation:', error);
            toast.error('Error performing Feedback operation');
        } finally {
            setIsLoading(false);
        }
    };

    const handleStatusActive = (fdq_id) => handleStatusChange(fdq_id, 'deactive');
    const handleStatusDeactive = (fdq_id) => handleStatusChange(fdq_id, 'active');

    return (
        <>
            <div className="relative bg-white border rounded p-2 mt-1">
                <div className="flex flex-col gap-2 p-2 rounded whitespace-nowrap">
                    <div className="py-1 px-2 flex justify-start items-center gap-2">
                        <span className='text-yellow-500'> <BsFillExclamationCircleFill /></span>
                        Are you sure you want to {selectedItem.fdq_status === 1 ? 'deactivate' : 'activate'} this link?
                    </div>
                    <div className="flex justify-end gap-2">
                        <div onClick={onCancel}>
                            <CustomButton2 label={"No"} variant="outlined" className="text-red-500 text-xs" />
                        </div>
                        <div onClick={() => selectedItem.fdq_status === 0 ? handleStatusDeactive(selectedItem.fdq_id) : handleStatusActive(selectedItem.fdq_id)}>
                            <CustomButton1 label={"Yes"} className="text-white bg-red-500 text-xs" />
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}

import Icon1 from "../../assets/1.svg";
import Icon2 from "../../assets/22.svg";
import Icon3 from "../../assets/3.svg";
import Icon4 from "../../assets/4.svg";
import Icon5 from "../../assets/5.svg";
import Icon6 from "../../assets/6.svg";
import Icon7 from "../../assets/7.svg";
import Icon8 from "../../assets/8.svg";
import Icon9 from "../../assets/9.svg";
import Icon10 from "../../assets/10.svg";
import Icon11 from "../../assets/11.svg";
import Icon12 from "../../assets/12.svg";
import Icon13 from "../../assets/13.svg";
import IconCrown from "../../assets/crown.svg";
import { AiOutlineSearch } from "react-icons/ai";
import { Checkbox } from "@mui/material";
import { Radio } from "@mui/material";
import { AiOutlineCrown } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";

import { useRef, useMemo } from "react";
import { NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";

import { onlyIcon } from "../../Admin.page";
import { setOnlyIcon } from "../../Admin.page";
import { useState, useEffect } from "react";

function NavTabs() {
    // FOR NAVIGATION TABS
    let currentTab = null;
    let tabRef = [];
    let subTabRef = [];
    const [selectedMainField, setSelectedMainField] = useState('DASHBOARD')

    function Tab({ selectedTitle, onChange, index, icon, title, subTabs }) {
        const [activeSubTab, setActiveSubTab] = useState(subTabs[0][0]);
        const [isSubTabsOpen, setIsSubTabsOpen] = useState(subTabs?.length > 1 && title === selectedMainField ? true : false);
        const [activeSubSubTab, setActiveSubSubTab] = useState(subTabs?.length > 1 && title === selectedMainField ? subTabs[0][1][0] : null)
        // Set the activeSubTab to the first sub-tab when the component mounts
        const { pathname } = useLocation();

        const navigate = useNavigate()

        const toggleSubTabs = () => {
            onChange(title);
            if (Array.isArray(subTabs) && subTabs?.length > 1) {
                setIsSubTabsOpen(prevState => !prevState);
                if (!isSubTabsOpen) {
                    setActiveSubTab(subTabs[0][0]);
                    setActiveSubSubTab(subTabs[0][1][0][1]);
                    navigate(`/admin/${subTabs[0][1]}`);
                }
            } else {
                setIsSubTabsOpen(!isSubTabsOpen);
                navigate(`/admin/${subTabs[0][1]}`);
            }
        };

        const handleSubTabChange = (subTitle, subTabKey) => {
            setActiveSubTab(subTitle)
            if (Array.isArray(subTabKey)) {
                navigate(`/admin/${subTitle.toLowerCase().replace(/\s+/g, '-').replace(/^-+|-+$/g, '')}/${subTabKey[0][1]}`)
                return
            }
            navigate(`/admin/${subTabKey}`)
        }


        return (
            <div className="tab" >
                <div className={`${selectedTitle === title ? 'text-red-500 border-b border-red-500' : 'text-[#3D3D3D]'} text-md cursor-pointer main-tab flex items-center gap-3 py-3 my-1  px-2 transition-all duration-500  overflow-hidden`} style={{ marginInline: "10px" }} onClick={toggleSubTabs}>
                    <div className={`flex pl-2 justify-center items-center ${onlyIcon ? 'w-full' : ''}`}> {/* Added w-full class to center icon if onlyIcon is true */}
                        <span className="">{icon}</span>
                        {onlyIcon ? null : <div className=" ml-2 ">{title}</div>}
                    </div>
                </div>

                {onlyIcon ? null : <>
                    {isSubTabsOpen && subTabs?.length > 1 && title === selectedMainField && (
                        <div className="sub-tabs ml-2 overflow-auto">
                            {subTabs?.map(([subTitle, subTabKey], index) => (
                                <div>
                                    <div className="sub-tab-container cursor-pointer flex items-center " key={subTabKey}>
                                        <Radio
                                            checked={activeSubTab === subTitle}
                                            onChange={() => handleSubTabChange(subTitle, subTabKey)}
                                        />
                                        <div
                                            className={` sub-tab flex items-center gap-3 py-2 my-1 rounded press-1 transition-all duration-500  ${pathname.split("/").pop() === subTabKey || (pathname.split("/")?.length > 3 && activeSubTab === subTitle) ? "text-first " : ""}`}
                                            onClick={() => handleSubTabChange(subTitle, subTabKey)}
                                        >
                                            {subTitle}
                                        </div>
                                    </div>
                                    <div className="ml-4 ">
                                        {
                                            Array.isArray(subTabs[index][1]) && subTabs[index][1]?.length > 0 && (
                                                subTabs[index][1].map((item, subTabIndex) => (
                                                    <div key={subTabIndex} className="flex cursor-pointer ml-3 items-center">
                                                        <h1>{item?.[1] === activeSubSubTab?.[1]}</h1>
                                                        <Radio
                                                            checked={pathname.split("/").pop() === item[1]}
                                                            onChange={() => {
                                                                setActiveSubTab(subTabs?.[index]?.[0]);
                                                                setActiveSubSubTab(item);
                                                                navigate(`/admin/${subTabs[index][0].toLowerCase().replace(/\s+/g, '-').replace(/^-+|-+$/g, '')}/${item[1]}`);
                                                            }}
                                                        />
                                                        <div
                                                            onClick={() => {
                                                                setActiveSubTab(subTabs[index][0])
                                                                setActiveSubSubTab(item)
                                                                navigate(`/admin/${subTabs[index][0].toLowerCase().replace(/\s+/g, '-').replace(/^-+|-+$/g, '')}/${item[1]}`)
                                                            }
                                                            }
                                                            className={`${pathname.split("/").pop() === item[1] ? 'text-first ' : ''}`}
                                                        >{item?.[0]}</div>
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>

                                </div>
                            ))}
                        </div>
                    )}
                </>}
                {/* Render the content for the activeSubTab here */}
            </div>
        );
    }

    // NAVIGATION TABS RETURN
    return (
        <div className="overflow-auto rounded whitespace-nowrap lg:flex">
            {/* SEARCH BOX */}
            {/* {onlyIcon ? null :
                <div className="flex items-center px-2 py-2 my-1 gap-4 border rounded bg-[#BDC8DD] ">
                    <AiOutlineSearch className="icon min-w-[30px]" />
                    {onlyIcon ? null : (
                        <input
                            type="search"
                            className="w-full bg-transparent shrink  placeholder:text-fourth"
                            placeholder="SEARCH"
                            name=""
                            id=""
                        />
                    )}
                </div>} */}

            {/* <Tab
                index={0}
                icon={<img src={Icon1} className="icon text-inherit ring-first" alt="" />}
                title="DASHBOARD"
                onChange={setSelectedMainField}
                selectedTitle={selectedMainField}
                subTabs={[
                    ["Dashboard", "dashboard"],
                ]}
                disabled={true}
                to="dashboard"
            /> */}
            <Tab
                index={1}
                icon={<AiOutlineCrown className="text-2xl" />}
                title="ADMINISTRATOR"
                onChange={setSelectedMainField}
                selectedTitle={selectedMainField}
                subTabs={formatSubTabs([
                    ["Admin Profile", "admin-profile"],
                ])}
            />
            <Tab
                index={5}
                icon={<AiOutlineUser className="text-2xl" />}
                title="Manage FeedBack"
                onChange={setSelectedMainField}
                selectedTitle={selectedMainField}
                subTabs={[
                    ["FeedBack Category", "manage-feedback"],
                ]}
            />
        </div>
    );
}

// Function to format subTabs to 
const formatSubTabs = (subTabs) => {
    return subTabs.map(([title, link]) => {
        if (Array.isArray(link)) {
            return [title, formatSubTabs(link)];
        } else {
            return [title, link];
        }
    });
}

export default NavTabs;

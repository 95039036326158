import React, { useState } from 'react';

import CustomButton1 from '../../general-components/CustomButton1.component';
import CustomButton2 from '../../general-components/CustomButton2.component';
import ToolTipY from '../../general-components/ToolTipY.component';

import {  BsFillExclamationCircleFill } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { AiOutlineEnter } from "react-icons/ai";
import { MdDeleteOutline } from 'react-icons/md';
import "../../../../index.css";

export default function SingleQuestion({ id, index, onDelete, onQuestionChange, initialQuestion }) {
    const [isEditingQuestion, setIsEditingQuestion] = useState(false);
    const [isEditingOptions, setIsEditingOptions] = useState([false, false, false]);
    const [questionText, setQuestionText] = useState(initialQuestion.questionText || 'Type Question');
    const [optionTexts, setOptionTexts] = useState(initialQuestion.options || ['Option 1', 'Option 2', 'Option 3']);

    const handleEditQuestionClick = () => setIsEditingQuestion(true);

    const handleQuestionChange = (event) => {
        setQuestionText(event.target.value);
        onQuestionChange(id, event.target.value, optionTexts);
    };

    const handleOptionChange = (optionIndex) => (event) => {
        const newOptions = [...optionTexts];
        newOptions[optionIndex] = event.target.value;
        setOptionTexts(newOptions);
        onQuestionChange(id, questionText, newOptions);
    };

    const handleEditOptionClick = (optionIndex) => {
        const newEditingStates = [...isEditingOptions];
        newEditingStates[optionIndex] = true;
        setIsEditingOptions(newEditingStates);
    };

    return (
        <div className='flex flex-row w-full bg-white border-b border-gray-300'>
            <div className="py-4 px-6 w-[70%]">
                <div className='flex flex-col gap-2'>
                    <div className='flex items-center gap-1 text-black'>
                        <span>{index + 1}.</span>
                        {isEditingQuestion ? (
                            <div className='border border-red-500 w-full py-1 px-2 flex justify-between items-center'>
                                <input
                                    type='text'
                                    value={questionText}
                                    onChange={handleQuestionChange}
                                    className='text-sm font-light w-full'
                                    onBlur={() => setIsEditingQuestion(false)}
                                    autoFocus
                                />
                                <span className='text-gray-500'><AiOutlineEnter /></span>
                            </div>
                        ) : (
                            <>
                                <span>{questionText}</span>
                                <span className='text-red-500 cursor-pointer' onClick={handleEditQuestionClick}><CiEdit /></span>
                            </>
                        )}
                    </div>
                    {optionTexts.map((optionText, optionIndex) => (
                        <label key={optionIndex} className='ps-2 font-light text-black flex items-center gap-1'>
                            <input type='radio' name={`question-${id}`} value={optionIndex + 1} disabled /> {String.fromCharCode(97 + optionIndex)}.
                            {isEditingOptions[optionIndex] ? (
                                <div className='border border-red-500 py-1 px-2 flex justify-between items-center'>
                                    <input
                                        type='text'
                                        value={optionText}
                                        onChange={handleOptionChange(optionIndex)}
                                        className='text-sm font-light'
                                        onBlur={() => {
                                            const newEditingStates = [...isEditingOptions];
                                            newEditingStates[optionIndex] = false;
                                            setIsEditingOptions(newEditingStates);
                                        }}
                                        autoFocus
                                    />
                                    <span className='text-gray-500'><AiOutlineEnter /></span>
                                </div>
                            ) : (
                                <>
                                    <span>{optionText}</span>
                                    <span className='text-red-500 cursor-pointer' onClick={() => handleEditOptionClick(optionIndex)}><CiEdit /></span>
                                </>
                            )}
                        </label>
                    ))}
                </div>
            </div>
            <div className="py-4 px-6 w-[15%]">
                <div className='border border-dashed border-gray-500 px-2 py-1 text-center'>
                    Single
                </div>
            </div>
            <div>
                <ToolTipY
                    title={
                        <div className="py-4 px-6 text-red-500 w-[15%] text-2xl">
                            <MdDeleteOutline />
                        </div>
                    }
                    content={
                        <>
                            <div className="flex flex-col gap-2 p-2 overflow-hidden rounded whitespace-nowrap">
                                <div className="py-1 px-2 flex justify-start items-center gap-2">
                                    <span className='text-yellow-500'> <BsFillExclamationCircleFill /></span>
                                    Are You Sure to Delete ?
                                </div>
                                <div className="flex justify-center gap-2">
                                    <div>
                                        <CustomButton2 label={"No"} variant="outlined" className="text-red-500 text-xs" />
                                    </div>
                                    <div onClick={() => onDelete(id)} >
                                        <CustomButton1 label={"Yes"} className="text-white bg-red-500 text-xs" />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    height="80px"
                    direction="top"
                    position="right"
                ></ToolTipY>
            </div>
        </div>
    );
};

import React, { useState } from 'react';
import { AiOutlineEnter } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from 'react-icons/md';

function CommentBoxQuestion({ id, index, onDelete, onQuestionChange }) {
    const [isEditingQuestion, setIsEditingQuestion] = useState(false);
    const [questionText, setQuestionText] = useState('Type Question');
    const [answerText, setAnswerText] = useState('');

    const handleEditQuestionClick = () => setIsEditingQuestion(true);
    const handleQuestionChange = (event) => {
        setQuestionText(event.target.value);
        onQuestionChange(id, questionText, answerText); // Notify parent of change
    };
    const handleAnswerChange = (event) => {
        setAnswerText(event.target.value);
        onQuestionChange(id, questionText, event.target.value); // Notify parent of change
    };

    return (
        <>
            <div className='py-5 border-b border-gray-300'>
                <div className='flex flex-row items-center w-full'>
                    <div className="px-6 text-black w-[70%]">
                        <div className='flex items-center gap-1 text-black'>
                            <span>{index + 1}.</span>
                            {isEditingQuestion ? (
                                <div className='border border-red-500 w-full py-1 px-2 flex justify-between items-center'>
                                    <input
                                        type='text'
                                        value={questionText}
                                        onChange={handleQuestionChange}
                                        className='text-sm font-light w-full'
                                        onBlur={() => setIsEditingQuestion(false)} // Automatically stop editing on blur
                                        autoFocus
                                    />
                                    <span className='text-gray-500'><AiOutlineEnter /></span>
                                </div>
                            ) : (
                                <>
                                    <span>{questionText}</span>
                                    <span className='text-red-500 cursor-pointer' onClick={handleEditQuestionClick}><CiEdit /></span>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="px-6 w-[15%]">
                        <div className='border border-dashed border-gray-500 px-2 py-1 text-center'>
                            Comment
                        </div>
                    </div>
                    <div className="px-6 text-red-500 w-[15%] text-2xl">
                        <MdDeleteOutline onClick={() => onDelete(id)} />
                    </div>
                </div>
                <div className='pt-3 px-6'>
                    <textarea
                        className="p-2 border rounded grow w-full bg-gray-100 text-xs placeholder:text-xs "
                        id="id"
                        disabled
                        value={answerText}
                        onChange={handleAnswerChange}
                    />
                </div>
            </div>
        </>
    )
};

export default CommentBoxQuestion;

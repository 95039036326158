import React from "react";

function ToggleSwitch({ label, state, setState, disabled }) {
  // Convert numeric state to boolean
  const isActive = state === 1;

  return (
    <div className="flex flex-col gap-1">
      <label htmlFor="toggle" className="text-xs font-400 uppercase">
        {label}
      </label>
      <div
        className={`${
          isActive ? "bg-red-500" : "bg-gray-300"
        } border flex justify-center items-center rounded-full h-[30px] w-[60px] p-1 ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        }`}
        onClick={() => !disabled && setState(isActive ? 0 : 1)} // Toggle between 0 and 1
      >
        <div
          className={`${
            isActive ? "translate-x-4" : "-translate-x-4"
          } w-[24px] h-[24px] bg-white rounded-full shadow-md transform transition-transform`}
        ></div>
      </div>
    </div>
  );
}

export default ToggleSwitch;

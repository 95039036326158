// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { Button } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// COMPONENTS
import PopUp from "../../general-components/PopUp.componenet";
import CustomButton6 from "../../general-components/CustomButton6.component";

// ICONS
import { IoMdAdd } from "react-icons/io";
import { AiOutlineSearch, AiOutlineSetting } from "react-icons/ai";
import { GrPrevious, GrNext } from "react-icons/gr";

import RoleProfileAdd from "../../forms-components/RoleProfile/RoleProfileAdd";
import RoleProfileEdit from "../../forms-components/RoleProfile/RoleProfileEdit";
import AccessControl from "../../forms-components/RoleProfile/AccessControl";
import { IoMdRefresh } from "react-icons/io";
import { TbArrowsMaximize } from "react-icons/tb";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomTextField6 from "../../general-components/CustomTextField6.component";
import CustomButton7 from "../../general-components/CustomButton7.component";

export default function RoleProfile() {
    let [popupRoleAdd, setPopupRoleAdd] = useState(false);
    let [popupRoleEdit, setPopupRoleEdit] = useState(false);
    const [roleId, setRoleId] = useState(0);
    const [role, setRole] = useState("");
    const [roleAdd, setRoleAdd] = useState(0);
    const [popupRole, setPopupRole] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [Roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null); // New state for selected role
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedAccess, setSelectedAccess] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [selectedCId, setSelectedCId] = useState(0);
    const [selectedRlId, setSelectedRlId] = useState(0);
    const recordsPerPage = 10;
    const [permissions, setPermissions] = useState({
        canRead: false,
        canCreate: false,
        canUpdate: false,
    });

    const [searchRole, setSearchRole] = useState("");
    const [SearchDate, setSearchDate] = useState("");
    const [roleStatus, setRoleStatus] = useState("Active");

    const checkLoginStatus = async () => {
        const token = localStorage.getItem('ASARFI_CRM_authToken');

        try {
            const userResponse = await fetch(`${process.env.REACT_APP_MAIN_URL}/auth/isLogin`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (userResponse.status === 200) {
                const userData = await userResponse.json();
                if (userData && userData.isLoggedIn) {
                    setIsLoggedIn(true);
                    const rolePermissions = userData.user.currentRoutes
                        .find(route => route.cust_key === 'mm_administrator')
                        ?.routes.find(subRoute => subRoute.cust_key === 'mm_administrator_manage_roles')
                        ?.custPermissions;

                    setPermissions({
                        canRead: rolePermissions?.canRead || false,
                        canCreate: rolePermissions?.canCreate || false,
                        canUpdate: rolePermissions?.canUpdate || false,
                    });

                    fetchRoles();
                } else {
                    // Handle not logged in scenario
                    toast.error("User not logged in");
                }
            } else {
                // Handle error response from isLogin API
                toast.error("Failed to verify login status");
            }
        } catch (error) {
            toast.error("Error verifying login status");
        }
    };

    useEffect(() => {
        checkLoginStatus();
    }, [])

    const fetchRoles = async (page, filters = {}) => {
        const token = localStorage.getItem('ASARFI_CRM_authToken');
        const userData = localStorage.getItem('ASARFI_CRM_userData');
        const parsedData = JSON.parse(userData);

        const params = {
            page: page,
            sort: JSON.stringify({ "rl_created_at": "desc" }),
            filters: JSON.stringify({
                "current": page,
                "pageSize": recordsPerPage,
                ...filters
            }),
            c_id: parsedData?.c_id,
        };

        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/manageendusers/role/fetch_paginated`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                params
            });
            if (response.data && Array.isArray(response.data.data)) {
                setRoles(response.data.data);
                setTotalRecords(response.data.total); // Assuming the total number of records is returned
                console.log("Roles data set successfully:", response.data.data);
            } else {
                console.log("Invalid response format", response.data);
            }
        } catch (error) {
            toast.error("Error fetching Roles");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchRoles(currentPage, {
            "rl_name": searchRole,
            "rl_status_view": roleStatus,
            "rl_created_at": SearchDate,
        });
    }, [currentPage]);

    const handleInquire = () => {
        fetchRoles(1, {
            "rl_name": searchRole,
            "rl_status_view": roleStatus,
            "rl_created_at": SearchDate,
        });
        setCurrentPage(1);
    };

    const handleReset = () => {
        setSearchRole("");
        setSearchDate("");
        setRoleStatus("Active");
        fetchRoles(1, {
            "rl_name": "",
            "rl_status_view": "Active",
            "rl_created_at": "",
        });
        setCurrentPage(1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleNextPage = () => {
        const totalPages = Math.ceil(totalRecords / recordsPerPage);
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    const startRecord = (currentPage - 1) * recordsPerPage + 1;
    const endRecord = Math.min(currentPage * recordsPerPage, totalRecords);

    const handleEditClick = (roleData) => {
        setSelectedRole(roleData); // Set the selected role
        setRole(roleData.rl_name); // Populate the role name in the form
        setRoleAdd(roleData.rl_status); // Populate the toggle state
        setRoleId(roleData.rl_id)
        setPopupRoleEdit(true); // Show the popup
    };

    const handleRefresh = () => {
        window.location.reload();
    }

    const handleMaximize = () => {
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { // Firefox
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { // IE/Edge
            elem.msRequestFullscreen();
        }
    }

    const handleRoletButtonClick = (cId, rlId) => {
        fetchRoleAccessById(cId, rlId);
        setSelectedCId(cId);
        setSelectedRlId(rlId);
        setPopupRole(true);
    };


    const fetchRoleAccessById = async (cId, rlId) => {
        const token = localStorage.getItem('ASARFI_CRM_authToken');

        setIsLoading(true);
        try {
            const url = new URL(`${process.env.REACT_APP_MAIN_URL}/manageendusers/role/fetch_role_route`);
            url.searchParams.append('c_id', cId);
            url.searchParams.append('rl_id', rlId);

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            const responseData = await response.json();
            if (response.ok) {
                setSelectedAccess(responseData);

            } else {
                console.error("Role Access Id failed", responseData.message);
                toast.error(responseData.message || "Role Access Id failed");
            }
        } catch (error) {
            console.error("Error performing Export Role Access Id operation:", error);
            toast.error("Error performing Export Role Access Id operation");
        } finally {
            setIsLoading(false);
        }
    };

    const userName = localStorage.getItem('ASARFI_CRM_username');

    return (
        <>

            <div className="px-5 mb-4 relative">
                <div className="watermark-container  absolute inset-0 pointer-events-none opacity-20 grid grid-cols-5 gap-4 p-10" >
                    {Array.from({ length: 4 }).map((_, i) => (
                        <h1 key={i} className="watermark-text text-sm font-bold text-gray-400 rotate-[-30deg] flex-wrap">{userName}</h1>
                    ))}
                </div>
                <div className="p-2 px-6 bg-white flex items-center justify-between gap-2">
                    <div>
                        <div className="flex flex-row gap-1">
                            <label htmlFor="id" className="flex items-center text-sm font-[400]">
                                Role:
                            </label>
                            <input
                                type="text"
                                className="p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black"
                                id="id"
                                placeholder="Please Enter"
                                value={searchRole}
                                onChange={(e) => setSearchRole(e.target.value)}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center gap-2">
                            <label id="">Status :</label>
                            <div className="flex items-center">
                                <input
                                    id="status-active"
                                    type="radio"
                                    value="Active"
                                    name="status"
                                    className="w-4 h-4 text-red-500 bg-gray-100 border-gray-300"
                                    checked={roleStatus === "Active"}
                                    onChange={(e) => setRoleStatus(e.target.value)}
                                />
                                <label htmlFor="status-active" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    Active
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="status-inactive"
                                    type="radio"
                                    value="Inactive"
                                    name="status"
                                    className="w-4 h-4 text-red-500 bg-gray-100 border-gray-300"
                                    checked={roleStatus === "Inactive"}
                                    onChange={(e) => setRoleStatus(e.target.value)}
                                />
                                <label htmlFor="status-inactive" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    Inactive
                                </label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <label htmlFor="created-at" className="flex items-center text-sm font-[400]">
                                Created At:
                            </label>
                            <input
                                type="datetime-local"
                                className="p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black"
                                id="created-at"
                                placeholder="Please Enter"
                                value={SearchDate}
                                onChange={(e) => setSearchDate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex gap-5 ">
                        <div>
                            <CustomButton7 label="Reset" variant="outlined" className="hover:text-red-500 text-black" onClick={handleReset} />
                        </div>
                        <div>
                            <CustomButton6 label="Inquire" className="text-white bg-first" type="submit" disabled={isLoading} onClick={handleInquire} />
                        </div>
                    </div>
                </div>
            </div>

            {/* 4 BUTTONS + TABLE */}
            <div className="px-5 relative">
                <div className="watermark-container flex items-center absolute inset-0 pointer-events-none opacity-20 grid grid-cols-5 gap-4 p-10" >
                    {Array.from({ length: 8 }).map((_, i) => (
                        <h1 key={i} className="watermark-text text-sm font-bold text-gray-400 rotate-[-30deg] flex-wrap">{userName}</h1>
                    ))}
                </div>
                <div className="flex flex-col p-2 bg-white">
                    <div className="flex flex-row justify-end items-center flex-wrap max-w-[100%] w-[98%] mt-2 mx-auto gap-2 bg-white md:flex-nowrap">
                        {permissions.canCreate && (
                            <CustomButton6
                                label="Add New Record"
                                icon={<IoMdAdd />}
                                className="bg-first text-white shrink grow md:grow-0 max-w-[50%]"
                                onClick={() => setPopupRoleAdd(true)}
                            />
                        )}
                        <div onClick={handleRefresh} className="hover:text-red-500 text-2xl flex items-center font-light">
                            <Tooltip title="Refresh" placement="top" arrow>
                                <p><IoMdRefresh /></p>
                            </Tooltip>
                        </div>
                        <div onClick={handleMaximize} className="hover:text-red-500 text-2xl flex items-center font-light pe-1">
                            <Tooltip title="Full Screen" placement="top" arrow>
                                <p><TbArrowsMaximize /></p>
                            </Tooltip>
                        </div>
                    </div>

                    {permissions.canRead && (
                        <>
                            {/* TABLE */}
                            <div className="max-w-[100%] w-[98%] mt-5 mx-auto hide-scrollbar overflow-auto table-container">
                                <table className="w-full custom-table bg-white" border={1}>
                                    <thead>
                                        <tr className="table-heading">
                                            <td>#</td>
                                            <td>Role</td>
                                            <td>Status</td>
                                            <td>Created At</td>
                                            <td>Created By</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            <tr>
                                                <td colSpan="8" className="text-center">Loading...</td>
                                            </tr>
                                        ) : (
                                            Roles.map((roleData, index) => (
                                                <tr key={roleData.id}>
                                                    <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                                    <td>{roleData.rl_name}</td>
                                                    <td>
                                                        <div>
                                                            <h2 style={{ color: roleData.rl_status === 1 ? 'green' : 'red' }}>
                                                                {roleData.rl_status === 1 ? 'Active' : 'Inactive'}
                                                            </h2>
                                                        </div>
                                                    </td>
                                                    <td>{roleData.rl_created_at}</td>
                                                    <td>{roleData.fullname}</td>
                                                    <td className="flex justify-center">
                                                        {permissions.canUpdate && (
                                                            <div>
                                                                <Button
                                                                    className="text-red-500 py-0 border border-red-500 bg-white capitalize font-semibold w-[80px]"
                                                                    type="submit"
                                                                    onClick={() => handleEditClick(roleData)}
                                                                    variant="outlined"
                                                                    size="small"
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </div>
                                                        )}
                                                        <div>
                                                            <Button
                                                                className="p-1 bg-slate-200 border border-gray-400 text-black text-xs grow w-[150px]"
                                                                type="submit"
                                                                onClick={() => handleRoletButtonClick(roleData.c_id, roleData.rl_id)}
                                                                variant="outlined"
                                                                size="small"
                                                            >
                                                                Access Control
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className="flex justify-end items-center gap-2 mt-4 mx-auto max-w-[100%] w-[98%] my-5">
                                {/* Display Records Info */}
                                <div className="font-light text-sm">
                                    {startRecord} - {endRecord} of {totalRecords} items
                                </div>

                                {/* Pagination Controls */}
                                <div className="flex justify-between items-center">
                                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
                                        <GrPrevious />
                                    </button>
                                    <span>
                                        <div className="flex">
                                            {Array.from({ length: totalPages }, (_, index) => index + 1).map(pageNumber => (
                                                <button
                                                    key={pageNumber}
                                                    className={`px-2.5 py-0.5 mx-1 ${currentPage === pageNumber ? 'border border-red-500 text-red-500 bg-white' : 'bg-gray-200'}`}
                                                    onClick={() => handlePageChange(pageNumber)}
                                                >
                                                    {pageNumber}
                                                </button>
                                            ))}
                                        </div>
                                    </span>
                                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                                        <GrNext style={{ color: '#6b7280' }} />
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>


            {popupRoleAdd && (
                <PopUp>
                    <RoleProfileAdd
                        onCancel={() => setPopupRoleAdd(false)}
                    />
                </PopUp>
            )
            }

            {
                popupRoleEdit && (
                    <PopUp>
                        <RoleProfileEdit
                            roleId={roleId}
                            role={role}
                            setRole={setRole}
                            roleAdd={roleAdd}
                            setRoleAdd={setRoleAdd}
                            selectedRole={selectedRole}
                            setSelectedRole={setSelectedRole}
                            onCancel={() => setPopupRoleEdit(false)}
                        />
                    </PopUp>
                )
            }

            {console.log("gdfgdfgg-->", selectedAccess)}

            {
                popupRole && (
                    <PopUp>
                        <AccessControl
                            selectedCId={selectedCId}
                            selectedRlId={selectedRlId}
                            selectedAccess={selectedAccess}
                            onCancel={() => setPopupRole(false)}
                        />
                    </PopUp>
                )
            }

            <ToastContainer autoClose={2000} />
        </>
    )
}

import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomButton6 from '../../general-components/CustomButton6.component';
import ToggleSwitch from '../../general-components/ToggleSwitch.component';
import CustomButton7 from '../../general-components/CustomButton7.component';
import CustomSelect1 from '../../general-components/CustomSelect1.component';
import CustomEmail from '../../general-components/CustomEmail.component';
import CustomNumber from '../../general-components/CustomNumber.component';
import CustomTextArea from '../../general-components/CustomTextArea.component';
import CustomPassword2 from '../../general-components/CustomPassword2.component';
import CustomSelectMulti from "../../general-components/CustomSelectMulti.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
]

export default function EmployeeProfileEdit({ onCancel, selectedEmp }) {
    const [isLoading, setIsLoading] = useState(false); // Added isLoading state
    const [userId, setUsetId] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [mNo, setMNo] = useState("");
    const [address, setAddress] = useState("");
    const [password, setPassword] = useState("");
    const [checkboxAdd, setCheckboxAdd] = useState(0);

    const [roleData, setRoleData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [reportingToData, setReportingToData] = useState([]);

    const [roleId, setRoleId] = useState("");
    const [reportingToId, setReportingToId] = useState("");
    const [categoryId, setCategoryId] = useState([]);
    const [genderId, setGenderId] = useState("")

    const [roleName, setRoleName] = useState("");
    const [reportingToName, setReportingToName] = useState("");
    const [categoryName, setCategoryName] = useState([]);
    const [genderName, setGenderName] = useState("")

    const token = localStorage.getItem('ASARFI_CRM_authToken');
    const userData = localStorage.getItem('ASARFI_CRM_userData');
    const parsedData = JSON.parse(userData);

    useEffect(() => {
        if (selectedEmp) {
            setUsetId(selectedEmp?.id);
            setFullName(selectedEmp?.fullname);
            setEmail(selectedEmp?.email);
            setMNo(selectedEmp?.mobile);
            setAddress(selectedEmp?.address);
            setPassword(selectedEmp?.password);
            setCheckboxAdd(selectedEmp?.status);

            setRoleId(selectedEmp?.rl_id);
            setReportingToId(selectedEmp?.rl_reporting_to);
            setCategoryId(selectedEmp?.category_id ? selectedEmp.category_id.split(',') : []);
            setGenderId(selectedEmp?.gender);

            setRoleName(selectedEmp?.rl_name);
            setReportingToName(selectedEmp?.rprtng_to_name);
            setCategoryName(selectedEmp?.category_names ? selectedEmp.category_names.split(',') : []);
            setGenderName(selectedEmp?.gender);
        }
    }, [selectedEmp])


    const fetchRoles = async () => {
        const params = {
            c_id: parsedData?.c_id,
        };

        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/manageendusers/role/fetch_all`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                params
            });
            if (response.data && Array.isArray(response.data)) {
                setRoleData(response.data);
                console.log("Roles data set successfully:", response.data);
            } else {
                console.log("Invalid response format", response.data);
            }
        } catch (error) {
            toast.error("Error fetching Roles");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCategories = async () => {
        const params = {
            c_id: parsedData?.c_id,
        };

        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/manageendusers/feedback/feedback_categories/fetch_all`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                params
            });
            if (response.data && Array.isArray(response.data)) {
                setCategoryData(response.data);
                console.log("Category data set successfully:", response.data);
            } else {
                console.log("Invalid response format", response.data);
            }
        } catch (error) {
            toast.error("Error fetching Category");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchReportingTo = async (selectedRoleId) => {
        const params = {
            rl_id: selectedRoleId,
            c_id: parsedData?.c_id,
        };

        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/manageendusers/role/fetch_rprtng_to_users`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                params
            });
            if (response.data && Array.isArray(response.data)) {
                const transformedData = response.data.map(parent => ({
                    label: parent.label,
                    options: parent.value.map(child => ({
                        value: child.id,
                        label: child.label
                    }))
                }));
                setReportingToData(transformedData);
                console.log("Reporting To data set successfully:", transformedData);
            } else {
                console.log("Invalid response format", response.data);
            }
        } catch (error) {
            toast.error("Error fetching Reporting To");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchRoles();
        fetchCategories();
    }, []);

    const handleRolesChange = (selectedOption) => {
        if (selectedOption) {
            const selectedRoleId = selectedOption.value;
            setRoleId(selectedRoleId);
            setRoleName(selectedOption.label);
            fetchReportingTo(selectedRoleId);
        } else {
            setRoleId(null);
            setRoleName("");
            fetchReportingTo();
        }
    };

    const handleCategoryChange = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.value);
        const selectedNames = selectedOptions.map(option => option.label);
        setCategoryId(selectedIds);
        setCategoryName(selectedNames);
    };

    const handleGenderChange = (selectedOption) => {
        if (selectedOption) {
            setGenderId(selectedOption.value);
            setGenderName(selectedOption.label)
        } else {
            setGenderId(null);
            setGenderName("")
        }
    };

    const handleReportingChange = (selectedOption) => {
        // setReportingToId(selectedOption.value);
        // setReportingToName(selectedOption.label)
        if (selectedOption) {
            setReportingToId(selectedOption ? selectedOption.value : "");
            setReportingToName(selectedOption ? selectedOption.label : "");
        } else {
            setReportingToId(null);
            setReportingToName("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const requestBody = {
                id: userId,
                rl_id: roleId ? String(roleId) : "",  // Convert to string
                category_id: categoryId || [],
                c_id: parsedData?.c_id,
                rl_reporting_to: reportingToId ? String(reportingToId) : "",  // Convert to string
                ctry_id: 0,
                st_id: 0,
                cty_id: 0,
                fullname: fullName,
                gender: genderId || "",
                email: email,
                mobile: mNo,
                address: address || "",
                password: password,
                status: checkboxAdd,
            };
            console.log("request-body-->", requestBody);

            const response = await fetch(`${process.env.REACT_APP_MAIN_URL}/manageendusers/user/update`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (response.ok) {
                toast.success("Record Inserted successfully");

                setTimeout(() => {
                    window.location.reload(); // Reload the page or fetch updated list
                }, 1000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing User operation:", error);
            toast.error("Error performing User operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-5 bg-white rounded-lg">
                <div className="flex bg-white p-4 text-black justify-between rounded-t-lg border-b">
                    <h1 className="text-xl font-weight-[400]">Edit Employee</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="p-6 gap-4 max-h-[70vh] overflow-y-auto ">
                        <div className="grid grid-cols-1  gap-4 md:grid-cols-3 max-w-full w-[1000px] ">
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="id" className="text-sm font-400 ">
                                        Role Name <span className="text-red-500">*</span>
                                    </label>
                                    <Select
                                        options={roleData.map(role => ({
                                            value: role.rl_id,
                                            label: role.rl_name
                                        }))}
                                        onChange={handleRolesChange}
                                        className="text-black text-xs select-bar"
                                        id="roleId"
                                        isSearchable
                                        isClearable
                                        value={roleId ? { value: roleId, label: roleName } : null}
                                        menuPortalTarget={document.body} // This line ensures the dropdown opens outside the form
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            menu: base => ({
                                                ...base,
                                                fontSize: '12px', // Adjust font size for the entire menu
                                            }),
                                            option: (base, state) => ({
                                                ...base,
                                                fontSize: '12px', // Adjust font size for each option
                                                backgroundColor: state.isSelected ? '#e5e7eb' : base.backgroundColor,
                                                color: state.isSelected ? 'black' : base.color,
                                            }),
                                        }}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#e5e7eb',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="id" className="text-sm font-[400] ">
                                        Reporting To
                                    </label>

                                    <Select
                                        // options={reportingToData.map(data => ({
                                        //     value: data.id,
                                        //     label: data.label
                                        // }))}
                                        options={reportingToData}
                                        isClearable
                                        isLoading={isLoading}
                                        onChange={handleReportingChange}
                                        value={reportingToId ? { value: reportingToId, label: reportingToName } : null}
                                        className="text-black text-xs select-bar "
                                        id="id"
                                        isSearchable
                                        closeMenuOnSelect={true}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#e5e7eb',
                                            },
                                        })}
                                        menuPortalTarget={document.body} // This line ensures the dropdown opens outside the form
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            menu: base => ({
                                                ...base,
                                                fontSize: '12px', // Adjust font size for the entire menu
                                            }),
                                            option: (base, state) => ({
                                                ...base,
                                                fontSize: '12px', // Adjust font size for each option
                                                backgroundColor: state.isSelected ? '#e5e7eb' : base.backgroundColor,
                                                color: state.isSelected ? 'black' : base.color,
                                            }),
                                        }}
                                    />

                                </div>
                            </div>
                            <div>
                                <CustomTextField2 label="Full Name" placeholder="Enter"
                                    value={fullName}
                                    onChange={setFullName}
                                />
                            </div>
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="id" className="text-sm font-[400] ">
                                        Gender
                                    </label>

                                    <Select
                                        options={genderOptions}
                                        onChange={handleGenderChange}
                                        value={genderId ? { value: genderId, label: genderName } : null}
                                        className="text-black text-xs select-bar "
                                        id="id"
                                        isSearchable
                                        isClearable
                                        closeMenuOnSelect={true}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#e5e7eb',
                                            },
                                        })}
                                        menuPortalTarget={document.body} // This line ensures the dropdown opens outside the form
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            menu: base => ({
                                                ...base,
                                                fontSize: '12px', // Adjust font size for the entire menu
                                            }),
                                            option: (base, state) => ({
                                                ...base,
                                                fontSize: '12px', // Adjust font size for each option
                                                backgroundColor: state.isSelected ? '#e5e7eb' : base.backgroundColor,
                                                color: state.isSelected ? 'black' : base.color,
                                            }),
                                        }}
                                    />

                                </div>
                            </div>
                            <div>
                                <CustomEmail label="Email" placeholder="Enter"
                                    value={email}
                                    onChange={setEmail}
                                />
                            </div>
                            <div>
                                <CustomNumber label="Mobile Number" placeholder="Enter"
                                    value={mNo}
                                    onChange={setMNo}
                                />
                            </div>
                            <div>
                                <CustomTextArea label="Address" placeholder="Enter" isRequired={false}
                                    value={address}
                                    onChange={setAddress}
                                />
                            </div>
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="id" className="text-sm font-[400] ">
                                        Category
                                    </label>

                                    <Select
                                        options={categoryData.map(data => ({
                                            value: data.fc_id,
                                            label: data.fc_name
                                        }))}
                                        onChange={handleCategoryChange}
                                        value={categoryId.map((id, index) => ({
                                            value: id,
                                            label: categoryName[index]
                                        }))}
                                        className="text-black text-xs select-bar "
                                        id="id"
                                        isSearchable
                                        isMulti
                                        closeMenuOnSelect={true}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#e5e7eb',
                                            },
                                        })}
                                        menuPortalTarget={document.body} // This line ensures the dropdown opens outside the form
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                            menu: base => ({
                                                ...base,
                                                fontSize: '12px', // Adjust font size for the entire menu
                                            }),
                                            option: (base, state) => ({
                                                ...base,
                                                fontSize: '12px', // Adjust font size for each option
                                                backgroundColor: state.isSelected ? '#e5e7eb' : base.backgroundColor,
                                                color: state.isSelected ? 'black' : base.color,
                                            }),
                                        }}
                                    />

                                </div>
                            </div>
                            <div>
                                <CustomPassword2 label="Password" placeholder="Enter Password"
                                    value={password}
                                    onChange={setPassword}
                                />
                            </div>
                            <div>
                                <ToggleSwitch
                                    label={"Is Active"}
                                    state={checkboxAdd}
                                    setState={setCheckboxAdd}
                                    disabled={false} // Set to true if you want to disable the toggle
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end gap-5 pt-5 px-5">
                        <div onClick={onCancel}>
                            <CustomButton7 label={"Cancel"} variant="outlined" className="hover:text-red-500 text-black" />
                        </div>
                        <div>
                            <CustomButton6 label={"Submit"} className="text-white bg-first" type="submit" disabled={isLoading} />
                        </div>
                    </div>
                </form>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}

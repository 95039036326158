import React from 'react';
import Select from 'react-select';
import '../../assets/css/selectbar.css';

function CustomSelect1(props) {
    const { value, label, isRequired, options, onChange, readOnly, isMulti } = props;
    return (
        <div className="flex flex-col gap-1">
            <label htmlFor="id" className="text-xs font-400 ">
                {props.label.toUpperCase()}
                {isRequired && <span className="text-red-500 gap-3">*</span>}
            </label>

            <Select
                options={options}
                className="text-black text-xs select-bar uppercase"
                id="id"
                isMulti={isMulti}
                isSearchable
                isClearable
                menuPosition={'fixed'} // Set menuPosition to fixed
                isDisabled={readOnly}
                onChange={onChange}
                closeMenuOnSelect={true}
                theme={theme => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary: '#e5e7eb',
                    },
                })}
            />

        </div>
    );
}

CustomSelect1.defaultProps = {
    isRequired: true,
};

export default CustomSelect1;

import React, { useState } from 'react';
import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomButton6 from '../../general-components/CustomButton6.component';
import ToggleSwitch from '../../general-components/ToggleSwitch.component';
import CustomButton7 from '../../general-components/CustomButton7.component';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function FeedBackCategoryAdd({ onCancel }) {
    const [categoryName, setCategoryName] = useState("");
    const [categoryStatus, setCategoryStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(false); // Added isLoading state

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const token = localStorage.getItem('ASARFI_CRM_authToken');
            const userData = localStorage.getItem('ASARFI_CRM_userData');
            const parsedData = JSON.parse(userData);

            const requestBody = {
                c_id: parsedData?.c_id,
                fc_name: categoryName,
                fc_status: categoryStatus,
            };

            console.log("request-body-->", requestBody);

            const response = await fetch(`${process.env.REACT_APP_MAIN_URL}/manageendusers/feedback/feedback_categories/create`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (response.ok) {
                if (responseData.message === "Record is already exists") {
                    toast.error("Duplicate entry is not permissable");
                } else {
                    toast.success("Record Inserted successfully");
                }

                setTimeout(() => {
                    window.location.reload(); // Reload the page or fetch updated list
                }, 1000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Category operation:", error);
            toast.error("Error performing Category operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-5 bg-white rounded-lg">
                <div className="flex bg-white p-4 text-black justify-between rounded-t-lg border-b">
                    <h1 className="text-xl font-weight-[400]">Add Category</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="p-4 mx-auto w-[500px] border-b">
                        <table className="w-[100%] mx-auto popup-table">
                            <tbody>
                                <tr>
                                    <td className="w-[70%]">
                                        <CustomTextField2
                                            label={"Category"}
                                            placeholder={"ENTER"}
                                            required
                                            value={categoryName}
                                            onChange={setCategoryName} // Corrected onChange handler
                                        ></CustomTextField2>
                                    </td>
                                    <td className="align-bottom w-[30%]">
                                        <ToggleSwitch
                                            label={"Is Active"}
                                            state={categoryStatus}
                                            setState={setCategoryStatus}
                                            disabled={false} // Set to true if you want to disable the toggle
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="flex justify-end gap-5 pt-5 px-5">
                        <div onClick={onCancel}>
                            <CustomButton7 label={"Cancel"} variant="outlined" className="hover:text-red-500 text-black" />
                        </div>
                        <div>
                            <CustomButton6 label={"Submit"} className="text-white bg-first" type="submit" disabled={isLoading} />
                        </div>
                    </div>
                </form>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    )
}


// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { Button, Tooltip } from "@mui/material";

// COMPONENTS
import PopUp from "../../general-components/PopUp.componenet";
import CustomButton6 from "../../general-components/CustomButton6.component";

// ICONS
import { IoMdAdd } from "react-icons/io";
import { AiOutlineSearch, AiOutlineSetting } from "react-icons/ai";
import { GrPrevious, GrNext } from "react-icons/gr";
import { IoMdRefresh } from "react-icons/io";
import { TbArrowsMaximize } from "react-icons/tb";

import RoleHierarchyAdd from "../../forms-components/RoleHierarchy/RoleHierarchyAdd";
import RoleHierarchyEdit from "../../forms-components/RoleHierarchy/RoleHierarchyEdit";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton7 from "../../general-components/CustomButton7.component";

export default function RoleHierarchy() {
  let [popupRoleHierarchyAdd, setPopupRoleHierarchyAdd] = useState(false);
  let [popupRoleHierarchyEdit, setPopupRoleHierarchyEdit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [Roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null); // New state for selected role
  const [selectedRoleId, setSelectedRoleId] = useState(null); // New state for selected role
  const [roleId, setRoleId] = useState("");
  const [roleAsignId, setRoleAsignId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleAsignName, setRoleAsignName] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const recordsPerPage = 10;
  const [permissions, setPermissions] = useState({
    canRead: false,
    canCreate: false,
    canUpdate: false,
  });

  const [searchRole, setSearchRole] = useState("");
  const [SearchDate, setSearchDate] = useState("");
  const [searchReport, setSearchReport] = useState("");

  const checkLoginStatus = async () => {
    const token = localStorage.getItem('ASARFI_CRM_authToken');

    try {
      const userResponse = await fetch(`${process.env.REACT_APP_MAIN_URL}/auth/isLogin`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });

      if (userResponse.status === 200) {
        const userData = await userResponse.json();
        if (userData && userData.isLoggedIn) {
          setIsLoggedIn(true);
          const rolePermissions = userData.user.currentRoutes
            .find(route => route.cust_key === 'mm_administrator')
            ?.routes.find(subRoute => subRoute.cust_key === 'mm_administrator_manage_role_hierarchy')
            ?.custPermissions;

          setPermissions({
            canRead: rolePermissions?.canRead || false,
            canCreate: rolePermissions?.canCreate || false,
            canUpdate: rolePermissions?.canUpdate || false,
          });
          fetchRoleHierarchies();
        } else {
          // Handle not logged in scenario
          toast.error("User not logged in");
        }
      } else {
        // Handle error response from isLogin API
        toast.error("Failed to verify login status");
      }
    } catch (error) {
      toast.error("Error verifying login status");
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, [])

  const fetchRoleHierarchies = async (page, filters = {}) => {
    const token = localStorage.getItem('ASARFI_CRM_authToken');
    const userData = localStorage.getItem('ASARFI_CRM_userData');
    const parsedData = JSON.parse(userData);

    const params = {
      page: page,
      sort: JSON.stringify({}),
      filters: JSON.stringify({
        "current": page,
        "pageSize": recordsPerPage,
        ...filters
      }),
      c_id: parsedData?.c_id,
    };

    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/manageendusers/rolehierarchy/fetch_paginated`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        params
      });
      if (response.data && Array.isArray(response.data.data)) {
        setRoles(response.data.data);
        setTotalRecords(response.data.total); // Assuming the total number of records is returned
        console.log("Roles data set successfully:", response.data.data);
      } else {
        console.log("Invalid response format", response.data);
      }
    } catch (error) {
      toast.error("Error fetching Roles");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRoleHierarchies(currentPage, {
      "rlh_baseusername": searchRole,
      "rlh_reportingtousername": searchReport,
      "rlh_created_at": SearchDate,
    });
  }, [currentPage]);

  const handleInquire = () => {
    fetchRoleHierarchies(1, {
      "rlh_baseusername": searchRole,
      "rlh_reportingtousername": searchReport,
      "rlh_created_at": SearchDate,
    });
    setCurrentPage(1);
  };

  const handleReset = () => {
    setSearchRole("");
    setSearchDate("");
    setSearchReport("");
    fetchRoleHierarchies(1, {
      "rlh_baseusername": "",
      "rlh_reportingtousername": "Active",
      "rlh_created_at": "",
    });
    setCurrentPage(1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const startRecord = (currentPage - 1) * recordsPerPage + 1;
  const endRecord = Math.min(currentPage * recordsPerPage, totalRecords);

  const handleEditClick = (roleData) => {
    setSelectedRole(roleData); // Set the selected role
    setSelectedRoleId(roleData.rlh_id)
    setRoleId(roleData.rlh_baseuserid);
    setRoleName(roleData.rlh_baseusername);
    setRoleAsignId(roleData.rlh_reportingtouserid);
    setRoleAsignName(roleData.rlh_reportingtousername);

    setPopupRoleHierarchyEdit(true);
  };

  const handleRefresh = () => {
    window.location.reload();
  }

  const handleMaximize = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { // IE/Edge
      elem.msRequestFullscreen();
    }
  }

  const userName = localStorage.getItem('ASARFI_CRM_username');
  return (
    <>
      <div className="px-5 mb-4 relative">
        <div className="watermark-container  absolute inset-0 pointer-events-none opacity-20 grid grid-cols-5 gap-4 p-10" >
          {Array.from({ length: 4 }).map((_, i) => (
            <h1 key={i} className="watermark-text text-sm font-bold text-gray-400 rotate-[-30deg] flex-wrap">{userName}</h1>
          ))}
        </div>
        <div className="p-2 px-6 bg-white flex items-center justify-between gap-2">
          <div>
            <div className="flex flex-row gap-1">
              <label htmlFor="id" className=" flex items-center text-sm font-[400]">
                Role:
              </label>
              <input
                type={"text"}
                className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                id="id"
                placeholder={"Please Enter"}
                value={searchRole}
                onChange={(e) => setSearchRole(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="flex flex-row gap-1">
              <label htmlFor="id" className=" flex items-center text-sm font-[400]">
                Reporting To:
              </label>
              <input
                type={"text"}
                className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                id="id"
                placeholder={"Please Enter"}
                value={searchReport}
                onChange={(e) => setSearchReport(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="flex flex-row gap-1">
              <label htmlFor="created-at" className=" flex items-center text-sm font-[400]">
                Created At:
              </label>
              <input
                type={"datetime-local"}
                className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                id="created-at"
                placeholder={"Please Enter"}
                value={SearchDate}
                onChange={(e) => setSearchDate(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-5 ">
            <div>
              <CustomButton7 label={"Reset"} variant="outlined" className="hover:text-red-500 text-black" onClick={handleReset} />
            </div>
            <div>
              <CustomButton6 label={"Inquire"} className="text-white bg-first" type="submit" disabled={isLoading} onClick={handleInquire} />
            </div>
          </div>
        </div>
      </div>


      {/* 4 BUTTONS + TABLE */}
      <div className="px-5 relative">
        <div className="watermark-container flex items-center absolute inset-0 pointer-events-none opacity-20 grid grid-cols-5 gap-4 p-10" >
          {Array.from({ length: 8 }).map((_, i) => (
            <h1 key={i} className="watermark-text text-sm font-bold text-gray-400 rotate-[-30deg] flex-wrap">{userName}</h1>
          ))}
        </div>
        <div className="flex flex-col p-2  bg-white  ">
          {/* 4 BUTTONS */}
          <div className="flex flex-row flex-wrap max-w-[100%] w-[98%] mt-2 mx-auto justify-end gap-2 bg-white md:flex-nowrap ">
            {permissions.canCreate && (
              <CustomButton6
                label={"Add New Record"}
                icon={<IoMdAdd />}
                className="text-white bg-first shrink grow md:grow-0 max-w-[50%]"
                onClick={() => setPopupRoleHierarchyAdd(true)}
              />
            )}

            <div onClick={handleRefresh} className=" hover:text-red-500 text-2xl flex items-center font-light">
              <Tooltip title="Refresh" placement="top" arrow>
                <p><IoMdRefresh /></p>
              </Tooltip>
            </div>
            <div onClick={handleMaximize} className=" hover:text-red-500 text-2xl flex items-center font-light pe-1">
              <Tooltip title="Full Screen" placement="top" arrow>
                <p><TbArrowsMaximize /></p>
              </Tooltip>
            </div>
          </div>

          {permissions.canRead && (
            <>
              {/* TABLE */}
              <div className=" max-w-[100%] w-[98%] mt-5 mx-auto hide-scrollbar overflow-auto table-container">
                <table className="w-full custom-table bg-white" border={1}>
                  <thead>
                    <tr className="table-heading">
                      <td className="">#</td>
                      <td>Role</td>
                      <td>Reporting To</td>
                      <td>Created At</td>
                      <td>Created By</td>
                      {permissions.canUpdate && (<td>Action</td>)}
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="8" className="text-center">Loading...</td>
                      </tr>
                    ) : (
                      Roles.map((roleData, index) => (
                        <tr key={roleData.id}>
                          <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                          <td>{roleData.rlh_baseusername}</td>
                          <td>{roleData.rlh_reportingtousername}</td>
                          <td>{roleData.rlh_created_at}</td>
                          <td>{roleData.fullname}</td>
                          {permissions.canUpdate && (
                            <td className="flex  justify-center">
                              <div className="">

                                <Button
                                  className="text-red-500 py-0 border border-red-500 bg-white capitalize font-semibold w-[80px]"
                                  type="submit"
                                  onClick={() => handleEditClick(roleData)}
                                  variant="outlined"
                                  size={"small"}
                                >
                                  Edit
                                </Button>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              <div className="flex justify-end items-center gap-2 mt-4 mx-auto max-w-[100%] w-[98%] my-5">
                {/* Display Records Info */}
                <div className="font-light text-sm">
                  {startRecord} - {endRecord} of {totalRecords} items
                </div>

                {/* Pagination Controls */}
                <div className="flex justify-between items-center">
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                  <span>
                    <div className="flex">
                      {Array.from({ length: totalPages }, (_, index) => index + 1).map(pageNumber => (
                        <button
                          key={pageNumber}
                          className={`px-2.5 py-0.5 mx-1 ${currentPage === pageNumber ? 'border border-red-500 text-red-500 bg-white' : 'bg-gray-200'}`}
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      ))}
                    </div>
                  </span>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    <GrNext style={{ color: '#6b7280' }} />
                  </button>
                </div>
              </div>
            </>
          )}

        </div>
      </div>

      {/* POPUP 1 : ADD */}
      {popupRoleHierarchyAdd && (
        <PopUp>
          <RoleHierarchyAdd
            onCancel={() => setPopupRoleHierarchyAdd(false)}
          />
        </PopUp>
      )}

      {/* POPUP 2 : EDIT */}
      {popupRoleHierarchyEdit && (
        <PopUp>
          <RoleHierarchyEdit
            selectedRoleId={selectedRoleId}
            roleId={roleId}
            setRoleId={setRoleId}
            roleAsignId={roleAsignId}
            setRoleAsignId={setRoleAsignId}
            roleName={roleName}
            setRoleName={setRoleName}
            roleAsignName={roleAsignName}
            setRoleAsignName={setRoleAsignName}

            onCancel={() => setPopupRoleHierarchyEdit(false)}
          />
        </PopUp>
      )}

      <ToastContainer autoClose={2000} />
    </>
  )
}

// CORE
import { useState } from "react";
import { Routes, Route } from "react-router-dom";

// COMPONENTS
import Navbar from "./components/general-components/NavBar.component";
import NavTabs from "./components/general-components/NavTabs.components";
import Dashboard from "./sections/Dashboard.section";
import ManageProfile from "./sections/ManageProfile.section";
import RolePermission from "./sections/RolePermission";
import FeedBackCategory from "./sections/FeedBackCategory";
import FeedBack from "./components/section-components/FeedBackCategory/FeedBack";
import FeedBackEdit from "./components/forms-components/FeedBack/FeedBackEdit";
import FeedBackAdd from "./components/forms-components/FeedBack/FeedBackAdd";
let [onlyIcon, setOnlyIcon] = [false, null];

function Admin() {
    [onlyIcon, setOnlyIcon] = useState(true);

    return (
        <section className="flex flex-col h-screen p-2 bg-slate-100 ">
            {/* NAVIGATION BAR */}
            <section className="bg-white">
                <Navbar />
            </section>

            {/* ASIDE TABS + DYNAMIC SECTIONS  */}
            <section className="flex gap-4 grow h-[100vh]">
                {/* ASIDE */}
                {/* <div
                    className={` bg-white${onlyIcon ? " w-[7.2vw]  min-w-[7.2vw]" : " w-[16.5vw]  "
                } p-3 hidden lg:block rounded mt-2 overflow-x-hidden border border-[#3D3D3D66]
                    overflow-auto  transition-all`}
                    style={{ borderRadius: "10px", overflow: "auto", height: "87vh"}}

                >
                    <NavTabs />
                </div> */}

                {/* DYNAMIC SECTIONS */}
                <div className="mt-2 pb-4 rounded grow overflow-hidden " style={{ borderRadius: "10px", overflowY: "auto", height: "89.5vh", width: "22vw" }}>
                    <Routes>
                        <Route index element={<Dashboard />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="admin-profile" element={<ManageProfile />} />                       
                        <Route path="role-permission" element={<RolePermission />} />                      
                        <Route path="manage-feedback" element={<FeedBackCategory/>} />
                        <Route path="feedback" element={<FeedBack/>} />
                        <Route path="feedback-add" element={<FeedBackAdd/>} />
                        <Route path="feedback-edit" element={<FeedBackEdit/>} />
                    </Routes>
                </div>
            </section>
        </section>
    );
}

export default Admin;
export { onlyIcon, setOnlyIcon };

// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import Title from "../components/general-components/Title.component";
import { Tab, TabContainer, TabContent } from "../components/general-components/Tab.component";
import RoleProfile from "../components/section-components/ManageProfile/RoleProfile";
import RoleHierarchy from "../components/section-components/ManageProfile/RoleHierarchy";
import EmployeeProfile from "../components/section-components/ManageProfile/EmployeeProfile";

function ManageProfile() {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className="flex flex-col h-full ">
            <div className="bg-slate-100 rounded grow" >
                <div className="bg-white">
                    <Title title1={"Manage Users "} />
                </div>
                {/* <div className="  flex  items-center flex-row flex-wrap justify-start gap-2 mt-5 md:flex-nowrap pb-5 ">
                    <button className="smlbtn"> Excel</button>
                </div> */}

                <div className="px-2  lg:px-4 my-5">
                    {/* 3 TABS */}
                    <TabContainer>
                        <Tab label="Role" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Role Hierarchy " index={1} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <Tab label="Employee" index={2} activeTab={activeTab} setActiveTab={setActiveTab} />
                    </TabContainer>
                </div>



                {/* TAB 2 CONTENT : ROLES */}
                <TabContent index={0} activeTab={activeTab}>
                    <div>
                        <RoleProfile />
                    </div>
                </TabContent>

                {/* TAB 3 CONTENTS : ROLE HIERARCHY */}
                <TabContent index={1} activeTab={activeTab}>
                    <div>
                        <RoleHierarchy />
                    </div>
                </TabContent>

                {/* TAB 4 CONTENTS : EMPLOYEE */}
                <TabContent index={2} activeTab={activeTab}>
                    <div>
                        <EmployeeProfile />
                    </div>
                </TabContent >
            </div >
        </div >
    );
}

export default ManageProfile;
import React from 'react'

// utils/cryptData.js
export function cryptData(method, data) {
    // Simple Base64 encoding example (replace with your actual encryption logic)
    if (method === 'enc') {
        return btoa(data); // Base64 encoding
    }
    return data;
}

import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Logo from "./assets/mainLogo.png";
import CustomEmail from "./components/general-components/CustomEmail.component";
import CustomPassword from "./components/general-components/CustomPassword.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomPassword1 from "./components/general-components/CustomPassword1.component";
import CustomEmail1 from "./components/general-components/CustomEmail1.component";
import { RiCopyrightLine } from "react-icons/ri";

function Login() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [responseData, setResponseData] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setLoading(true);

        const response = await fetch(`${process.env.REACT_APP_MAIN_URL}/auth/login`, {
            method: "POST",
            mode: "no-cors",
            headers: {
                // "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        const loginData = await response.json();
        console.log("Login response:", response);
        console.log("loginData:", loginData);


        if (response.ok) {
            // Assuming the backend sends a specific field in responseData to indicate success
            if (response.status === 202) {
                toast.success("Login successfully");
            } else {
                console.error("Login failed:", responseData.message);
                toast.error(responseData.message || "Login failed");
                setLoading(false);
            }
        } else {
            console.error("Login failed with status code:", response.status);
            toast.error(responseData.message || `Login failed with status code: ${response.status}`);
            setLoading(false);
        }

    };

    return (
        <>
            <div className="flex  justify-center min-h-[93.2vh] bg-cover bg-center bg-slate-100 bg-no-repeat bg-[url('./pages/assets/bg_main.svg')] ">
                {/* MAIN CONTAINER */}
                <div className="rounded-lg flex flex-col  overflow-hidden  m-6  w-[80%] max-w-[25rem]  ">
                    <div className=" flex items-center  justify-center  w-[100%] ">
                        <img src={Logo} className="w-[6rem] pt-3" alt="" />
                    </div>
                    <div className="max-w-full w-full">
                        <form className="px-6 " onSubmit={handleSubmit}>
                            <h1 className="text-center text-4xl text-black">Login</h1>

                            <div className="mt-4 mb-2">
                                <CustomEmail1
                                    // label="Email"
                                    placeholder="Enter Email"
                                    value={email}
                                    onChange={setEmail}
                                    isRequired={false}
                                />
                            </div>
                            <div className="mt-4">
                                <CustomPassword1
                                    // label="Password"
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={setPassword}
                                    isRequired={false}
                                />
                            </div>

                            <div className="mt-5 flex justify-center pb-5">
                                {/* <NavLink to="/admin" className="w-full min-h-full text-base font-semibold capitalize"> */}
                                <Button
                                    variant={"contained"}
                                    size={"small"}
                                    className={"flex gap-2 justify-center items-center relative  w-full bg-red-500"}
                                    type="submit"
                                >
                                    {loading ? "Loading..." : "Login"}
                                </Button>
                                {/* </NavLink> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="bg-slate-100 py-5 flex justify-center items-center gap-1 text-sm text-gray-400">
                <span><RiCopyrightLine /></span>
                <p className="font-light">2024 Powered by Analytics Valley</p>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    );
}

export default Login;

// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { Button, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

// COMPONENTS
import PopUp from "../components/general-components/PopUp.componenet";
import CustomButton6 from "../components/general-components/CustomButton6.component";
import Title from "../components/general-components/Title.component";

// ICONS
import { IoMdAdd, IoMdRefresh } from "react-icons/io";
import { AiOutlineSearch, AiOutlineSetting } from "react-icons/ai";
import { GrPrevious, GrNext } from "react-icons/gr";

import FeedBackCategoryAdd from "../components/forms-components/FeedBackCategory/FeedBackCategoryAdd";
import FeedBackCategoryEdit from "../components/forms-components/FeedBackCategory/FeedBackCategoryEdit";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TbArrowsMaximize } from "react-icons/tb";
import CustomButton7 from "../components/general-components/CustomButton7.component";

export default function FeedBackCategory() {
    let [popupAdd, setPopupAdd] = useState(false);
    let [popupEdit, setPopupEdit] = useState(false);

    const [categoryData, setCategoryData] = useState([]);
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState("");
    const [categoryStatus, setCategoryStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null); // New state for selected role
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const recordsPerPage = 10;
    const [permissions, setPermissions] = useState({
        canRead: false,
        canCreate: false,
        canUpdate: false,
    });

    const [searchCategory, setSearchCategory] = useState("");
    const [SearchDate, setSearchDate] = useState("");
    const [searchCategoryStatus, setSearchCategoryStatus] = useState("Active");

    const navigate = useNavigate();

    const checkLoginStatus = async () => {
        const token = localStorage.getItem('ASARFI_CRM_authToken');

        try {
            const userResponse = await fetch(`${process.env.REACT_APP_MAIN_URL}/auth/isLogin`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (userResponse.status === 200) {
                const userData = await userResponse.json();
                if (userData && userData.isLoggedIn) {
                    setIsLoggedIn(true);
                    const rolePermissions = userData.user.currentRoutes
                        .find(route => route.cust_key === 'mm_feedback')
                        ?.routes.find(subRoute => subRoute.cust_key === 'mm_endusers_manage_feedback_categories')
                        ?.custPermissions;

                    setPermissions({
                        canRead: rolePermissions?.canRead || false,
                        canCreate: rolePermissions?.canCreate || false,
                        canUpdate: rolePermissions?.canUpdate || false,
                    });
                    fetchCategories();
                } else {
                    // Handle not logged in scenario
                    toast.error("User not logged in");
                }
            } else {
                // Handle error response from isLogin API
                toast.error("Failed to verify login status");
            }
        } catch (error) {
            toast.error("Error verifying login status");
        }
    };

    useEffect(() => {
        checkLoginStatus();
    }, [])

    const fetchCategories = async (page, filters = {}) => {
        const token = localStorage.getItem('ASARFI_CRM_authToken');
        const userData = localStorage.getItem('ASARFI_CRM_userData');
        const parsedData = JSON.parse(userData);

        const params = {
            page: page,
            sort: JSON.stringify({ "fc_created_at": "desc" }),
            filters: JSON.stringify({ "current": page, "pageSize": recordsPerPage }),
            filters: JSON.stringify({
                "current": page,
                "pageSize": recordsPerPage,
                ...filters
            }),
            c_id: parsedData?.c_id,
        };

        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/manageendusers/feedback/feedback_categories/fetch_paginated`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                params
            });
            if (response.data && Array.isArray(response.data.data)) {
                setCategoryData(response.data.data);
                setTotalRecords(response.data.total); // Assuming the total number of records is returned
                console.log("categoryData data set successfully:", response.data.data);
            } else {
                console.log("Invalid response format", response.data);
            }
        } catch (error) {
            toast.error("Error fetching categoryData");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCategories(currentPage, {
            "fc_name": searchCategory,
            "fc_status_view": searchCategoryStatus,
            "fc_created_at": SearchDate,
        });
    }, [currentPage]);

    const handleInquire = () => {
        fetchCategories(1, {
            "fc_name": searchCategory,
            "fc_status_view": searchCategoryStatus,
            "fc_created_at": SearchDate,
        });
        setCurrentPage(1);
    };

    const handleReset = () => {
        setSearchCategory("");
        setSearchDate("");
        setSearchCategoryStatus("Active");
        fetchCategories(1, {
            "fc_name": "",
            "fc_status_view": "Active",
            "fc_created_at": "",
        });
        setCurrentPage(1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleNextPage = () => {
        const totalPages = Math.ceil(totalRecords / recordsPerPage);
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    const startRecord = (currentPage - 1) * recordsPerPage + 1;
    const endRecord = Math.min(currentPage * recordsPerPage, totalRecords);

    const handleEditClick = (catData) => {
        setSelectedCategory(catData); // Set the selected role
        setCategoryId(catData.fc_id)
        setCategoryName(catData.fc_name); // Populate the role name in the form
        setCategoryStatus(catData.fc_status); // Populate the toggle state
        setPopupEdit(true); // Show the popup
    };

    const encodeBase64 = (value) => {
        return btoa(value.toString());
    };

    const handleManageAccessClick = (cId, fcId) => {
        const encodedCId = encodeBase64(cId);
        const encodedFcId = encodeBase64(fcId);
        navigate(`/admin/feedback?c=${encodedCId}&ct=${encodedFcId}`);
    };

    const handleRefresh = () => {
        window.location.reload();
    }

    const handleMaximize = () => {
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { // Firefox
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { // IE/Edge
            elem.msRequestFullscreen();
        }
    }

    const userName = localStorage.getItem('ASARFI_CRM_username');


    return (
        <>



            <div className="flex flex-col h-full ">
                <div className="bg-slate-100 rounded grow" >
                    <div className="bg-white">
                        <Title title1={"Feedback Category"} />
                    </div>

                    <div className="px-5 mb-4 mt-5 relative">
                        <div className="watermark-container  absolute inset-0 pointer-events-none opacity-20 grid grid-cols-5 gap-4 p-10" >
                            {Array.from({ length: 4 }).map((_, i) => (
                                <h1 key={i} className="watermark-text text-sm font-bold text-gray-400 rotate-[-30deg] flex-wrap">{userName}</h1>
                            ))}
                        </div>
                        <div className="p-2 px-6 bg-white flex items-center justify-between gap-2">
                            <div>
                                <div className="flex flex-row gap-1">
                                    <label htmlFor="id" className=" flex items-center text-sm font-[400]">
                                        Category:
                                    </label>
                                    <input
                                        type={"text"}
                                        className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                                        id="id"
                                        placeholder={"Please Enter"}
                                        value={searchCategory}
                                        onChange={(e) => setSearchCategory(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center gap-2">
                                    <label id="">Status :</label>

                                    <div className="flex items-center">
                                        <input
                                            id="status-active"
                                            type="radio"
                                            value="Active"
                                            name="status"
                                            className="w-4 h-4 text-red-500 bg-gray-100 border-gray-300"
                                            checked={searchCategoryStatus === "Active"}
                                            onChange={(e) => setSearchCategoryStatus(e.target.value)}
                                        />
                                        <label htmlFor="status-active" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Active
                                        </label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            id="status-inactive"
                                            type="radio"
                                            value="Inactive"
                                            name="status"
                                            className="w-4 h-4 text-red-500 bg-gray-100 border-gray-300"
                                            checked={searchCategoryStatus === "Inactive"}
                                            onChange={(e) => setSearchCategoryStatus(e.target.value)}
                                        />
                                        <label htmlFor="status-inactive" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Inactive
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-row gap-1">
                                    <label htmlFor="created-at" className=" flex items-center text-sm font-[400]">
                                        Created At:
                                    </label>
                                    <input
                                        type={"datetime-local"}
                                        className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                                        id="created-at"
                                        placeholder={"Please Enter"}
                                        value={SearchDate}
                                        onChange={(e) => setSearchDate(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-5 ">
                                <div>
                                    <CustomButton7 label={"Reset"} variant="outlined" className="hover:text-red-500 text-black" onClick={handleReset} />
                                </div>
                                <div>
                                    <CustomButton6 label={"Inquire"} className="text-white bg-first" type="submit" disabled={isLoading} onClick={handleInquire} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" my-5 relative">
                        <div className="watermark-container flex items-center absolute inset-0 pointer-events-none opacity-20 grid grid-cols-5 gap-4 px-10 pt-0" >
                            {Array.from({ length: 8 }).map((_, i) => (
                                <h1 key={i} className="watermark-text text-sm font-bold text-gray-400 rotate-[-30deg] flex-wrap">{userName}</h1>
                            ))}
                        </div>
                        <div className="px-5 ">
                            <div className="flex flex-col p-2  bg-white  ">
                                <div className="flex flex-row flex-wrap max-w-[100%] w-[98%] mt-2 mx-auto justify-end gap-2 bg-white md:flex-nowrap ">
                                    {permissions.canCreate && (
                                        <CustomButton6
                                            label={"Add New Record"}
                                            icon={<IoMdAdd />}
                                            className="bg-first text-white shrink grow md:grow-0 max-w-[50%]"
                                            onClick={() => setPopupAdd(true)}
                                        />
                                    )}

                                    <div onClick={handleRefresh} className=" hover:text-red-500 text-2xl flex items-center font-light">
                                        <Tooltip title="Refresh" placement="top" arrow>
                                            <p><IoMdRefresh /></p>
                                        </Tooltip>
                                    </div>
                                    <div onClick={handleMaximize} className=" hover:text-red-500 text-2xl flex items-center font-light pe-1">
                                        <Tooltip title="Full Screen" placement="top" arrow>
                                            <p><TbArrowsMaximize /></p>
                                        </Tooltip>
                                    </div>
                                </div>

                                {permissions.canRead && (
                                    <>
                                        {/* TABLE */}
                                        <div className=" max-w-[100%] w-[98%] mt-5 mx-auto hide-scrollbar overflow-auto table-container">
                                            <table className="w-full custom-table bg-white" border={1}>
                                                <thead>
                                                    <tr className="table-heading">
                                                        <td className="">#</td>
                                                        <td>Category</td>
                                                        <td>Status</td>
                                                        <td>Created At</td>
                                                        <td>Created By</td>
                                                        {permissions.canUpdate && (<td>Action</td>)}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading ? (
                                                        <tr>
                                                            <td colSpan="8" className="text-center">Loading...</td>
                                                        </tr>
                                                    ) : (
                                                        categoryData.map((roleData, index) => (
                                                            <tr key={roleData.id}>
                                                                <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                                                <td>
                                                                    <div
                                                                        onClick={() => {
                                                                            if (roleData.fc_name) {
                                                                                handleManageAccessClick(roleData.c_id, roleData.fc_id);
                                                                            }
                                                                        }}
                                                                        className={`w-full min-h-full text-red-500 ${roleData.fc_name ? 'cursor-pointer' : ''}`}
                                                                        style={{ cursor: roleData.fc_name ? 'pointer' : 'default' }}
                                                                    >
                                                                        {roleData.fc_name}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <h2 style={{ color: roleData.fc_status === 1 ? 'green' : 'red' }}>
                                                                            {roleData.fc_status === 1 ? 'Active' : 'Inactive'}
                                                                        </h2>
                                                                    </div>
                                                                </td>

                                                                <td>{roleData.fc_created_at}</td>
                                                                <td>{roleData.fullname}</td>
                                                                {permissions.canUpdate && (
                                                                    <td className="flex  justify-center">
                                                                        <div className="">
                                                                            <Button
                                                                                className="text-red-500 py-0 border border-red-500 bg-white capitalize font-semibold w-[80px]"
                                                                                type="submit"
                                                                                onClick={() => handleEditClick(roleData)}
                                                                                variant="outlined"
                                                                                size={"small"}
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="flex justify-end items-center gap-2 mt-4 mx-auto max-w-[100%] w-[98%] my-5">
                                            {/* Display Records Info */}
                                            <div className="font-light text-sm">
                                                {startRecord} - {endRecord} of {totalRecords} items
                                            </div>

                                            {/* Pagination Controls */}
                                            <div className="flex justify-between items-center">
                                                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                                                    <GrPrevious />
                                                </button>
                                                <span>
                                                    <div className="flex">
                                                        {Array.from({ length: totalPages }, (_, index) => index + 1).map(pageNumber => (
                                                            <button
                                                                key={pageNumber}
                                                                className={`px-2.5 py-0.5 mx-1 ${currentPage === pageNumber ? 'border border-red-500 text-red-500 bg-white' : 'bg-gray-200'}`}
                                                                onClick={() => handlePageChange(pageNumber)}
                                                            >
                                                                {pageNumber}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </span>
                                                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                                                    <GrNext style={{ color: '#6b7280' }} />
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {popupAdd && (
                <PopUp>
                    <FeedBackCategoryAdd
                        onCancel={() => setPopupAdd(false)}
                    />
                </PopUp>
            )}

            {popupEdit && (
                <PopUp>
                    <FeedBackCategoryEdit
                        categoryId={categoryId}
                        categoryName={categoryName}
                        setCategoryName={setCategoryName}
                        categoryStatus={categoryStatus}
                        setCategoryStatus={setCategoryStatus}
                        selectedCategory={selectedCategory}

                        onCancel={() => setPopupEdit(false)}
                    />
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    )
}

import * as React from "react";
import { useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import { onlyIcon } from "../../Admin.page";
import { setOnlyIcon } from "../../Admin.page";
import NavTabs from "./NavTabs.components";
import ToolTipY from "./ToolTipY.component";
import IconBadge from "./IconBadge.component";

import { FiMenu } from "react-icons/fi";
import { BsBellFill } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { BiChevronDown } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { IoMdLock } from "react-icons/io";
import { BiLogOutCircle } from "react-icons/bi";
import { AiOutlineCaretDown, AiOutlineMenu } from "react-icons/ai";
import Logo from "../../assets/mainLogo.png";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../../index.css";

function Navbar() {
    let asideRef = useRef(null);
    let overlayRef = useRef(null);

    function open() {
        asideRef.current.style.width = "60%";
        overlayRef.current.style.width = "100%";
    }
    function close() {
        asideRef.current.style.width = "0px";
        overlayRef.current.style.width = "0px";
    }

    useEffect(function () {
        setOnlyIcon(false);
    }, []);

    const navigate = useNavigate("");

    const userName = localStorage.getItem('ASARFI_CRM_username');

    function Aside() {
        return (
            <div className="lg:hidden bg-white ">
                {/* ASIDE */}
                <div
                    className="fixed top-0 left-0 z-50 w-0 h-screen transition-all bg-white custom-shadow overflow-clip"
                    ref={asideRef}
                >
                    {/* CROSS BUTTON */}
                    <div className="p-5 md:p-6">
                        <RxCross2 className="icon press-3" onClick={close} />
                    </div>

                    {/* NAVTABS */}
                    <div
                        className="m-5 max-h-[80vh] overflow-x-hidden overflow-y-auto hide-scrollbar"
                        style={{ border: "1px solid #3D3D3D66" }}
                    >
                        <NavTabs />
                    </div>
                </div>

                {/* OVERLAY */}
                <div
                    className="fixed left-0 top-0 w-0 h-screen bg-[rgba(0,0,0,0.1)] custom-shadow z-40"
                    ref={overlayRef}
                    onClick={close}
                ></div>
            </div>
        );
    }

    const token = localStorage.getItem('ASARFI_CRM_authToken');

    const handleLogOut = async () => {
        const baseURL = process.env.REACT_APP_MAIN_URL;
        console.log("Base URL:", baseURL);
    
        if (!baseURL) {
            console.error("Base URL is not defined. Please set REACT_APP_MAIN_URL in your environment variables.");
            return;
        }
    
        try {
            const response = await fetch(`${baseURL}/auth/logout`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });
    
            const responseData = await response.json();
            if (response.status === 200) {
                localStorage.removeItem("ASARFI_CRM_username");
                localStorage.removeItem("ASARFI_CRM_authToken");
                localStorage.removeItem("ASARFI_CRM_userData");
    
                toast.success("Logout successfully");
    
                setTimeout(() => {
                    window.location.href = "/";  // Reload the page or fetch updated list
                }, 1000);
            } else {
                console.error("Error during logout", responseData.message);
                toast.error(responseData.message || "Error during logout");
            }
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };

    const dashboardClick = () => {
        navigate('/admin/dashboard')
    }

    return (
        <>
            <section>
                {/* ASIDE */}
                <Aside />

                {/* TOP NAVBAR */}
                <div className="flex gap-0 shadow-css ">
                    {/*  */}
                    <div className="">
                        {/* LEFT : MENU ICON */}
                        <div onClick={open} className="lg:hidden">
                            <FiMenu className="inline-block icon press-5" />
                        </div>
                        {/* LEFT : LOGO */}
                        <div
                            className="items-center  bg-white hidden lg:flex lg:w-[220px] relative"
                        // style={{ border: "1px solid #3D3D3D66" }}
                        >
                            <div className=" flex items-center" >
                                <img src={Logo} className="m-2 w-[2rem]" alt="" />
                                <p>Asarfi</p>
                            </div>
                            <div
                                className="w-[2.5rem] flex items-center  bg-first justify-center h-[2.5rem] border rounded-md text-white press-2 absolute right-[10px] max-hide-select"
                                onClick={() => setOnlyIcon(!onlyIcon)}
                            >
                                <AiOutlineMenu className={`transition ${onlyIcon ? " rotate-180 " : "  "} `} />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between px-10  lg:px-0 lg:pr-2 grow max-justify ">
                        <div className="flex items-center  gap-8    rounded-2xl bg-white max-hide">
                            <NavTabs />
                        </div>

                        {/* RIGHT : USER + NOTIFICATION */}
                        <div className="flex items-center  gap-8    rounded-2xl bg-white">
                            {/* USER */}
                            <div>
                                <ToolTipY
                                    title={
                                        <div className="flex items-center gap-2 px-2 ">
                                            {/* <div className="flex items-center justify-center p-2 rounded-full bg-first">
                                            <FaUserAlt className="icon text-white text-[15px] " />
                                        </div> */}
                                            <div className="text-md ">{userName}</div>
                                            {/* <div>
                                            <BiChevronDown className="" />
                                        </div> */}
                                        </div>
                                    }
                                    content={
                                        <>
                                            <div className="flex flex-col gap-2 p-2 overflow-hidden rounded whitespace-nowrap">
                                                {/* <Button size="small" className="justify-start text-left text-first">
                                                <div className="flex items-center gap-2 capitalize">
                                                    <IoMdLock className="inline-block text-2xl " />
                                                    <span className="mt-[2px]">Reset Password</span>
                                                </div>
                                            </Button> */}
                                                <Button size="small" className="justify-start text-left text-red-400">
                                                    <div className="flex items-center gap-2 capitalize" onClick={handleLogOut}>
                                                        <BiLogOutCircle className="inline-block text-2xl " />
                                                        <span className="mt-[2px] ">Logout</span>
                                                    </div>
                                                </Button>
                                            </div>
                                        </>
                                    }
                                    height="50px"
                                    direction="down"
                                    position="right"
                                ></ToolTipY>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ToastContainer autoClose={2000} />
        </>

    );
}

export default Navbar;

import React, { useState } from 'react';

function CustomTextArea(props) {
  const { label, isRequired, readOnly, value, onChange } = props;

  const handleChange = (event) => {
    // event.target.value = event.target.value;
    // const upperCaseValue = event.target.value;
    const value = event.target.value
    onChange(value);
  };

  return (
    <div className="flex flex-col gap-1">
      <label htmlFor="id" className="text-sm font-[400]">
        {label}
        {isRequired && <span className="text-red-500 gap-3">*</span>}
      </label>
      <textarea
        required={isRequired}
        className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs "
        id="id"
        readOnly={readOnly}
        value={value}
        placeholder={props.placeholder}
        onChange={handleChange}
      />
    </div>
  );
}

CustomTextArea.defaultProps = {
  isRequired: true,
  value: '',
  onChange: () => { },
};

export default CustomTextArea;

import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import Select from "react-select";

import CustomButton6 from '../../general-components/CustomButton6.component';
import CustomButton7 from '../../general-components/CustomButton7.component';
import CustomSelect1 from "../../general-components/CustomSelect1.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function RoleHierarchyEdit({
    onCancel,
    selectedRoleId,
    roleId,
    setRoleId,
    roleAsignId,
    setRoleAsignId,
    roleName,
    setRoleName,
    roleAsignName,
    setRoleAsignName
}) {
    const [roleData, setRoleData] = useState([]);
    const [roleAsignData, setRoleAsignData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const token = localStorage.getItem('ASARFI_CRM_authToken');
    const userData = localStorage.getItem('ASARFI_CRM_userData');
    const parsedData = JSON.parse(userData);

    const fetchRoles = async () => {
        const params = {
            rl_id: 0,
            c_id: parsedData?.c_id,
        };

        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/manageendusers/rolehierarchy/fetch_assigned_roles`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                params
            });
            if (response.data && Array.isArray(response.data)) {
                setRoleData(response.data);
                console.log("Roles data set successfully:", response.data);
            } else {
                console.log("Invalid response format", response.data);
            }
        } catch (error) {
            toast.error("Error fetching Roles");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAsignedRoles = async (selectedRoleId) => {
        const params = {
            rl_id: selectedRoleId,
            c_id: parsedData?.c_id,
        };

        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/manageendusers/rolehierarchy/fetch_selected_roles`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                params
            });
            if (response.data && Array.isArray(response.data)) {
                setRoleAsignData(response.data);
                console.log("Assigned roles data set successfully:", response.data);
            } else {
                console.log("Invalid response format", response.data);
            }
        } catch (error) {
            toast.error("Error fetching Assigned Roles");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    useEffect(() => {
        if (roleId) {
            fetchAsignedRoles(roleId);
        }
    }, [roleId]);

    const handleRolesChange = (selectedOption) => {
        if (selectedOption) {
            const selectedRoleId = selectedOption.value;
            setRoleId(selectedRoleId);
            setRoleName(selectedOption.label);
            fetchAsignedRoles(selectedRoleId);
        } else {
            setRoleId(null);
            setRoleName("");
            fetchAsignedRoles();
        }
    };



    const handleRolesAsignChange = (selectedOption) => {
        if (selectedOption) {
            setRoleAsignId(selectedOption ? selectedOption.value : "");
            setRoleAsignName(selectedOption ? selectedOption.label : "");
        } else {
            setRoleAsignId(null);
            setRoleAsignName("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestBody = {
                c_id: parsedData?.c_id,
                rlh_id: selectedRoleId,
                rlh_baseuserid: roleId,
                rlh_reportingtouserid: roleAsignId,
            };

            console.log("request-body-->", requestBody);

            const response = await fetch(`${process.env.REACT_APP_MAIN_URL}/manageendusers/rolehierarchy/update`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (response.ok) {
                toast.success("Record Updated successfully");

                setTimeout(() => {
                    window.location.reload(); // Reload the page or fetch updated list
                }, 1000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Role Hierarchy operation:", error);
            toast.error("Error performing Role Hierarchy operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-5 bg-white rounded-lg">
                <div className="flex bg-white p-4 text-black justify-between rounded-t-lg border-b">
                    <h1 className="text-xl font-weight-[400]">Edit Role Hierarchy</h1>
                    <div className="flex items-center text-sm"></div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="p-4 mx-auto w-[750px] border-b">
                        <table className="w-[100%] mx-auto popup-table">
                            <tbody>
                                <tr>
                                    <td className="w-[50%]">
                                        <div className="flex flex-col gap-1">
                                            <label htmlFor="id" className="text-xs font-400 ">
                                                Role Name <span className="text-red-500">*</span>
                                            </label>
                                            <Select
                                                options={roleData.map(role => ({
                                                    value: role.rl_id,
                                                    label: role.rl_name
                                                }))}
                                                onChange={handleRolesChange}
                                                className="text-black text-xs select-bar"
                                                id="roleId"
                                                isSearchable
                                                isClearable
                                                value={roleId ? { value: roleId, label: roleName } : null}
                                                menuPortalTarget={document.body} // This line ensures the dropdown opens outside the form
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    menu: base => ({
                                                        ...base,
                                                        fontSize: '12px', // Adjust font size for the entire menu
                                                    }),
                                                    option: (base, state) => ({
                                                        ...base,
                                                        fontSize: '12px', // Adjust font size for each option
                                                        backgroundColor: state.isSelected ? '#e5e7eb' : base.backgroundColor,
                                                        color: state.isSelected ? 'black' : base.color,
                                                    }),
                                                }}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#e5e7eb',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </td>
                                    <td className="w-[50%]">
                                        <div className="flex flex-col gap-1">
                                            <label htmlFor="id" className="text-xs font-400 ">
                                                Reporting To <span className="text-red-500">*</span>
                                            </label>
                                            <Select
                                                options={roleAsignData.map(role => ({
                                                    value: role.rl_id,
                                                    label: role.rl_name
                                                }))}
                                                onChange={handleRolesAsignChange}
                                                value={roleAsignId ? { value: roleAsignId, label: roleAsignName } : null}
                                                className="text-black text-xs select-bar"
                                                id="roleId"
                                                isClearable
                                                isSearchable
                                                menuPortalTarget={document.body} // This line ensures the dropdown opens outside the form
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    menu: base => ({
                                                        ...base,
                                                        fontSize: '12px', // Adjust font size for the entire menu
                                                    }),
                                                    option: (base, state) => ({
                                                        ...base,
                                                        fontSize: '12px', // Adjust font size for each option
                                                        backgroundColor: state.isSelected ? '#e5e7eb' : base.backgroundColor,
                                                        color: state.isSelected ? 'black' : base.color,
                                                    }),
                                                }}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 4,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#e5e7eb',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="flex justify-end gap-5 pt-5 px-5">
                        <div onClick={onCancel}>
                            <CustomButton7 label={"Cancel"} variant="outlined" className="hover:text-red-500 text-black" />
                        </div>
                        <div>
                            <CustomButton6 label={"Update"} className="text-white bg-first" type="submit" disabled={isLoading} />
                        </div>
                    </div>
                </form>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    );
}

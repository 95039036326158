// CORE
import * as React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// COMPONENTS
import Title from "../components/general-components/Title.component";
import CustomButton1 from "../components/general-components/CustomButton1.component";
import { Checkbox } from "@mui/material";
import { Tab, TabContainer } from "../components/general-components/Tab.component";

function RolePermission() {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const uniqueIdentifier = searchParams.get("tab");
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className="flex flex-col h-full bg-[#F9FBFF] ">
            <Title title1={"Admin Profile"} title2={"ADMINITSTRATOR"} />

            <div className="p-4 pt-8 pb-8  rounded grow" style={{ border: "1px solid rgba(61, 61, 61, 0.30)" }}>
                <h1> Role Permission</h1>
                <div className="px-2  lg:px-4 ">
                    {/* 3 TABS */}
                    <TabContainer>
                        <Tab label="Website Application" index={0} activeTab={activeTab} setActiveTab={setActiveTab} />
                        {/* Reporting To is renamed to Role Heirarchy */}

                        <Tab label="Mobile App Application" index={1} activeTab={activeTab} setActiveTab={setActiveTab} />

                    </TabContainer>
                </div>
                Sellect All<Checkbox></Checkbox>

                <div className="  flex flex-row md:justify-between items-center flex-wrap justify-start gap-1 mt-5 md:flex-nowrap ">
                    <div className="mt-2 w-[80vw]  hide-scrollbar overflow-auto table-container ">
                        <table className="w-full custom-table" border={1}>
                            <thead>
                                <tr className="table-heading bg-[#F9FBFF]">
                                    <td className="">#</td>
                                    <td>Permission Name</td>
                                    <td>
                                        {" "}
                                        {" View"}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        {"Add"}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        {"Edit"}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Lorem ipsum</td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Lorem ipsum</td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Lorem ipsum</td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Lorem ipsum</td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Lorem ipsum</td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <Checkbox></Checkbox>{" "}
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <div className="flex justify-center gap-5 bg-[#F9FBFF] p-8">
                    <div>
                        <CustomButton1
                            label={"Back"}
                            className=" text-white bg-first"
                            onClick={() => navigate(`/admin/admin-profile?tab=${uniqueIdentifier}`)}
                        />
                    </div>
                    <div >

                        <CustomButton1
                            label={"Update"}
                            className=" text-white bg-first"
                            onClick={() => navigate(`/admin/admin-profile?tab=${uniqueIdentifier}`)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RolePermission;

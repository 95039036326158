import { Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Login from "./pages/Login.page";
import Admin from "./pages/Admin.page";
import NotFound from "./pages/NotFound.page";
import ProtectedRoute from "./pages/components/general-components/ProtectedRoute ";

function App() {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Perform the user authentication logic here
        const token = localStorage.getItem("ASARFI_CRM_authToken");
        // Check if a token exists in localStorage
        const isAuthenticated = !!token;
        // Update the state based on the authentication status
        setCurrentUser(isAuthenticated);
        setLoading(false);
    }, []);

    if (loading) {
        // Still checking authentication status, show loading spinner or similar
        return <div>Loading...</div>;
    }

    return (
        <section className="app bg-white">
            <Routes>
                {/* Redirect authenticated users away from login */}
                <Route path="/" element={currentUser ? <Navigate to="/admin/manage-feedback" /> : <Login />} />

                {/* Public routes */}
                <Route path="/" element={<Login />} />
                <Route path="/*" element={<NotFound />} />

                {/* Protected routes */}
                <Route element={<ProtectedRoute isAuthenticated={currentUser} />}>
                    <Route path="/admin/*" element={<Admin />} />
                </Route>

                {/* Redirect to admin from root if authenticated */}
                {currentUser && <Route path="/" element={<Navigate to="/admin/manage-feedback" />} />}
            </Routes>
        </section>
    );
}

export default App;

import React, { useState } from 'react';
import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomButton6 from '../../general-components/CustomButton6.component';
import ToggleSwitch from '../../general-components/ToggleSwitch.component';
import CustomButton7 from '../../general-components/CustomButton7.component';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

export default function RoleProfileEdit({
  onCancel,
  roleId,
  role,
  setRole,
  roleAdd,
  setRoleAdd,
  selectedRole,
  setSelectedRole
}) {
  const [isLoading, setIsLoading] = useState(false); // Added isLoading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const token = localStorage.getItem('ASARFI_CRM_authToken');
      const userData = localStorage.getItem('ASARFI_CRM_userData');
      const parsedData = JSON.parse(userData);

      const requestBody = {
        c_id: parsedData?.c_id,
        rl_id: roleId,
        rl_name: role,
        rl_status: roleAdd,
      };

      console.log("request-body-->", requestBody);

      const response = await fetch(`${process.env.REACT_APP_MAIN_URL}/manageendusers/role/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success("Record Updated successfully");

        setTimeout(() => {
          window.location.reload(); // Reload the page or fetch updated list
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }
    } catch (error) {
      console.error("Error performing Role operation:", error);
      toast.error("Error performing Role operation");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="pb-5 bg-white rounded-lg">
        <div className="flex bg-white p-4 text-black justify-between rounded-t-lg border-b">
          <h1 className="text-xl font-weight-[400]">Edit Role</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="p-4 mx-auto w-[500px] border-b">
            <table className="w-[100%] mx-auto popup-table">
              <tbody>
                <tr>
                  <td className="w-[70%]">
                    <CustomTextField2
                      label={"Role"}
                      placeholder={"ENTER"}
                      required
                      value={role}
                      onChange={setRole} // Corrected onChange handler
                    ></CustomTextField2>
                  </td>
                  <td className="align-bottom w-[30%]">
                    <ToggleSwitch
                      label={"Is Active"}
                      state={roleAdd}
                      setState={setRoleAdd}
                      disabled={false} // Set to true if you want to disable the toggle
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex justify-end gap-5 pt-5 px-5">
            <div onClick={onCancel}>
              <CustomButton7 label={"Cancel"} variant="outlined" className="hover:text-red-500 text-black" />
            </div>
            <div>
              <CustomButton6 label={"Update"} className="text-white bg-first" type="submit" disabled={isLoading} />
            </div>
          </div>
        </form>
      </div>

      <ToastContainer autoClose={2000} />
    </>
  )
}

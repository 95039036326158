// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';

// COMPONENTS
import { Button, Tooltip } from "@mui/material";
import PopUp from "../../general-components/PopUp.componenet";
import CustomButton6 from "../../general-components/CustomButton6.component";

// ICONS
import { IoMdAdd, IoMdRefresh } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { GrPrevious, GrNext } from "react-icons/gr";

import EmployeeProfileAdd from "../../forms-components/EmployeeProfile/EmployeeProfileAdd";
import EmployeeProfileEdit from "../../forms-components/EmployeeProfile/EmployeeProfileEdit";
import EmployeeProfileView from "../../forms-components/EmployeeProfile/EmployeeProfileView";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TbArrowsMaximize } from "react-icons/tb";
import CustomButton7 from "../../general-components/CustomButton7.component";

export default function EmployeeProfile() {
  let [popupEmployeeEdit, setPopupEmployeeEdit] = useState(false);
  let [popupEmployeeAdd, setPopupEmployeeAdd] = useState(false);
  let [popupEmployeeView, setPopupEmployeeView] = useState(false);
  let [statusEmployeeView, setStatusEmployeeView] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState(null); // New state for selected role
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const recordsPerPage = 10;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [permissions, setPermissions] = useState({
    canRead: false,
    canCreate: false,
    canUpdate: false,
  });

  const [searchName, setSearchName] = useState("");
  const [searchGender, setSearchGender] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchMobile, setSearchMobile] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchDate, setsearchDate] = useState("");

  const checkLoginStatus = async () => {
    const token = localStorage.getItem('ASARFI_CRM_authToken');

    try {
      const userResponse = await fetch(`${process.env.REACT_APP_MAIN_URL}/auth/isLogin`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });

      if (userResponse.status === 200) {
        const userData = await userResponse.json();
        if (userData && userData.isLoggedIn) {
          setIsLoggedIn(true);
          const rolePermissions = userData.user.currentRoutes
            .find(route => route.cust_key === 'mm_administrator')
            ?.routes.find(subRoute => subRoute.cust_key === 'mm_administrator_manage_employees')
            ?.custPermissions;

          setPermissions({
            canRead: rolePermissions?.canRead || false,
            canCreate: rolePermissions?.canCreate || false,
            canUpdate: rolePermissions?.canUpdate || false,
          });
          fetchUsers();
        } else {
          // Handle not logged in scenario
          toast.error("User not logged in");
        }
      } else {
        // Handle error response from isLogin API
        toast.error("Failed to verify login status");
      }
    } catch (error) {
      toast.error("Error verifying login status");
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, [])

  const fetchUsers = async (page, filters = {}) => {
    const token = localStorage.getItem('ASARFI_CRM_authToken');
    const userData = localStorage.getItem('ASARFI_CRM_userData');
    const parsedData = JSON.parse(userData);

    const params = {
      page: page,
      // sort: JSON.stringify(),
      sort: JSON.stringify({ created_at: -1 }),
      filters: JSON.stringify({
        "current": page,
        "pageSize": recordsPerPage,
        ...filters
      }),
      c_id: parsedData?.c_id,
    };

    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/manageendusers/user/fetch_paginated`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        params
      });
      if (response.data && Array.isArray(response.data.data)) {
        setEmpData(response.data.data);
        setTotalRecords(response.data.total); // Assuming the total number of records is returned
        console.log("empData data set successfully:", response.data.data);
      } else {
        console.log("Invalid response format", response.data);
      }
    } catch (error) {
      toast.error("Error fetching empData");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(currentPage, {
      "fullname": searchName,
      "gender": searchGender,
      "email": searchEmail,
      "mobile": searchMobile,
      "address": searchAddress,
      // "category_names": searchCategory,
      "status_view": searchStatus,
      "created_at": searchDate,
    });
  }, [currentPage]);

  const handleInquire = () => {
    fetchUsers(1, {
      "fullname": searchName,
      "gender": searchGender,
      "email": searchEmail,
      "mobile": searchMobile,
      "address": searchAddress,
      // "category_names": searchCategory,
      "status_view": searchStatus,
      "created_at": searchDate,
    });
    setCurrentPage(1);
  };

  const handleReset = () => {
    setSearchName("");
    setSearchGender("");
    setSearchEmail("");
    setSearchMobile("");
    setSearchAddress("");
    setSearchCategory("");
    setSearchStatus("Active");
    setsearchDate("");
    fetchUsers(1, {
      "fullname": searchName,
      "gender": searchGender,
      "email": searchEmail,
      "mobile": searchMobile,
      "address": searchAddress,
      // "category_names": searchCategory,
      "status_view": searchStatus,
      "created_at": searchDate,
    });
    setCurrentPage(1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const startRecord = (currentPage - 1) * recordsPerPage + 1;
  const endRecord = Math.min(currentPage * recordsPerPage, totalRecords);

  const handleEditClick = (empData) => {
    setSelectedEmp(empData); // Set the selected role
    setPopupEmployeeEdit(true);
  };

  const handleRefresh = () => {
    window.location.reload();
  }

  const handleMaximize = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { // IE/Edge
      elem.msRequestFullscreen();
    }
  }

  const userName = localStorage.getItem('ASARFI_CRM_username');
  return (
    <>
      <div className=" mx-auto relative">
        <div className="watermark-container  absolute inset-0 pointer-events-none opacity-20 grid grid-cols-5 gap-4 p-10" >
          {Array.from({ length: 4 }).map((_, i) => (
            <h1 key={i} className="watermark-text text-sm font-bold text-gray-400 rotate-[-30deg] flex-wrap">{userName}</h1>
          ))}
        </div>
        <div className="px-5 mb-4">
          <div className="p-2 px-6 bg-white flex items-center justify-between gap-2">
            <div>
              <div className="flex flex-row gap-1">
                <label htmlFor="id" className=" flex items-center text-sm font-[400]">
                  Full Name:
                </label>
                <input
                  type={"text"}
                  className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                  id="id"
                  placeholder={"Please Enter"}
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </div>
            </div>
            <div>
              <div className="flex flex-row gap-1">
                <label htmlFor="id" className=" flex items-center text-sm font-[400]">
                  Gender:
                </label>
                <input
                  type={"text"}
                  className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                  id="id"
                  placeholder={"Please Enter"}
                  value={searchGender}
                  onChange={(e) => setSearchGender(e.target.value)}
                />
              </div>
            </div>
            <div>
              <div className="flex flex-row gap-1">
                <label htmlFor="id" className=" flex items-center text-sm font-[400]">
                  Email:
                </label>
                <input
                  type={"text"}
                  className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                  id="id"
                  placeholder={"Please Enter"}
                  value={searchEmail}
                  onChange={(e) => setSearchEmail(e.target.value)}
                />
              </div>
            </div>
            <div>
              <div className="flex flex-row gap-1">
                <label htmlFor="id" className=" flex items-center text-sm font-[400]">
                  Mobile:
                </label>
                <input
                  type={"text"}
                  className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                  id="id"
                  placeholder={"Please Enter"}
                  value={searchMobile}
                  onChange={(e) => setSearchMobile(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="p-2 px-6 bg-white flex items-center justify-between gap-2">

            <div>
              <div className="flex flex-row gap-1">
                <label htmlFor="id" className=" flex items-center text-sm font-[400]">
                  Address:
                </label>
                <input
                  type={"text"}
                  className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                  id="id"
                  placeholder={"Please Enter"}
                  value={searchAddress}
                  onChange={(e) => setSearchAddress(e.target.value)}
                />
              </div>
            </div>
            {/* <div>
              <div className="flex flex-row gap-1">
                <label htmlFor="id" className=" flex items-center text-sm font-[400]">
                  Category:
                </label>
                <input
                  type={"text"}
                  className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                  id="id"
                  placeholder={"Please Enter"}
                  value={searchCategory}
                  onChange={(e) => setSearchCategory(e.target.value)}
                />
              </div>
            </div> */}
            <div>
              <div className="flex items-center gap-2">
                <label id="">Status :</label>

                <div className="flex items-center">
                  <input
                    id="status-active"
                    type="radio"
                    value="Active"
                    name="status"
                    className="w-4 h-4 text-red-500 bg-gray-100 border-gray-300"
                    checked={searchStatus === "Active"}
                    onChange={(e) => setSearchStatus(e.target.value)}
                  />
                  <label htmlFor="status-active" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Active
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="status-inactive"
                    type="radio"
                    value="Inactive"
                    name="status"
                    className="w-4 h-4 text-red-500 bg-gray-100 border-gray-300"
                    checked={searchStatus === "Inactive"}
                    onChange={(e) => setSearchStatus(e.target.value)}
                  />
                  <label htmlFor="status-inactive" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Inactive
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-row gap-1">
                <label htmlFor="created-at" className=" flex items-center text-sm font-[400]">
                  Created At:
                </label>
                <input
                  type={"datetime-local"}
                  className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                  id="created-at"
                  placeholder={"Please Enter"}
                  value={searchDate}
                  onChange={(e) => setsearchDate(e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-5 ">
              <div>
                <CustomButton7 label={"Reset"} variant="outlined" className="hover:text-red-500 text-black" onClick={handleReset} />
              </div>
              <div>
                <CustomButton6 label={"Inquire"} className="text-white bg-first" type="submit" disabled={isLoading} onClick={handleInquire} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-5 relative">
        <div className="watermark-container   absolute inset-0 pointer-events-none opacity-20 grid grid-cols-5 gap-4 px-10" >
          {Array.from({ length: 4 }).map((_, i) => (
            <h1 key={i} className="watermark-text text-sm font-bold text-gray-400 rotate-[-30deg] flex-wrap">{userName}</h1>
          ))}
        </div>
        <div className="flex flex-col p-2  bg-white  ">

          <div className="flex flex-row flex-wrap max-w-[100%] w-[98%] mt-2 mx-auto justify-end gap-2 bg-white md:flex-nowrap ">
            {permissions.canCreate && (
              <CustomButton6
                label={"Add New Record"}
                icon={<IoMdAdd />}
                className="bg-first text-white shrink grow md:grow-0 max-w-[50%]"
                onClick={() => setPopupEmployeeAdd(true)}
              />
            )}

            <div onClick={handleRefresh} className=" hover:text-red-500 text-2xl flex items-center font-light">
              <Tooltip title="Refresh" placement="top" arrow>
                <p><IoMdRefresh /></p>
              </Tooltip>
            </div>
            <div onClick={handleMaximize} className=" hover:text-red-500 text-2xl flex items-center font-light pe-1">
              <Tooltip title="Full Screen" placement="top" arrow>
                <p><TbArrowsMaximize /></p>
              </Tooltip>
            </div>
          </div>
          {permissions.canRead && (
            <>
              {/* TABLE */}
              <div className=" max-w-[100%] w-[98%] mx-auto hide-scrollbar overflow-auto table-container mt-5">
                <table className="w-full custom-table" border={1}>
                  <thead>
                    <tr className="table-heading">
                      <td className="">#.</td>
                      <td>Roles</td>
                      <td>Reporting To</td>
                      <td>Full Name</td>
                      <td>Gender</td>
                      <td>Email</td>
                      <td>Mobile</td>
                      <td>Address</td>
                      <td>Category</td>
                      <td>Status</td>
                      <td>Created At</td>
                      <td>Created By</td>
                      {permissions.canCreate && (<td>Action</td>)}
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="12" className="text-center">Loading...</td>
                      </tr>
                    ) : (
                      empData.map((empData, index) => (
                        <tr key={empData.id}>
                          <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                          <td>{empData.rl_name || '-'}</td>
                          <td>{empData.rprtng_to_name || '-'}</td>
                          <td>{empData.fullname || '-'}</td>
                          <td>{empData.gender || '-'}</td>
                          <td>{empData.email || '-'}</td>
                          <td>{empData.mobile || '-'}</td>
                          <td>{empData.address || '-'}</td>
                          <td>{empData.category_names || '-'}</td>
                          <td>
                            <div>
                              <h2 style={{ color: empData.status === 1 ? 'green' : 'red' }}>
                                {empData.status === 1 ? 'Active' : 'Inactive'}
                              </h2>
                            </div>
                          </td>
                          <td>{empData.created_at || '-'}</td>
                          <td>{empData.fullname || '-'}</td>
                          {permissions.canUpdate && (<td className="flex  justify-center">
                            <div className="">
                              <Button
                                className="text-red-500 py-0 border border-red-500 bg-white capitalize font-semibold w-[80px]"
                                type="submit"
                                onClick={() => handleEditClick(empData)}
                                variant="outlined"
                                size={"small"}
                              >
                                Edit
                              </Button>
                            </div>
                          </td>
                          )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              <div className="flex justify-end items-center gap-2 mt-4 mx-auto max-w-[100%] w-[98%] my-5">
                {/* Display Records Info */}
                <div className="font-light text-sm">
                  {startRecord} - {endRecord} of {totalRecords} items
                </div>

                {/* Pagination Controls */}
                <div className="flex justify-between items-center">
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <GrPrevious />
                  </button>
                  <span>
                    <div className="flex">
                      {Array.from({ length: totalPages }, (_, index) => index + 1).map(pageNumber => (
                        <button
                          key={pageNumber}
                          className={`px-2.5 py-0.5 mx-1 ${currentPage === pageNumber ? 'border border-red-500 text-red-500 bg-white' : 'bg-gray-200'}`}
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      ))}
                    </div>
                  </span>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    <GrNext style={{ color: '#6b7280' }} />
                  </button>
                </div>
              </div>
            </>
          )}

        </div>
      </div>

      {/* POPUP 1 : ADD */}
      {popupEmployeeAdd && (
        <PopUp>
          <EmployeeProfileAdd
            onCancel={() => setPopupEmployeeAdd(false)}
          />
        </PopUp>
      )}

      {/* POPUP 2 : EDIT */}
      {popupEmployeeEdit && (
        <PopUp>
          <EmployeeProfileEdit
            selectedEmp={selectedEmp}
            onCancel={() => setPopupEmployeeEdit(false)}
          />
        </PopUp>
      )}

      {popupEmployeeView && (
        <PopUp>
          <EmployeeProfileView
            statusEmployeeView={statusEmployeeView}
            setStatusEmployeeView={setStatusEmployeeView}
            onCancel={() => setPopupEmployeeView(false)}
          />
        </PopUp>
      )}

      <ToastContainer autoClose={2000} />
    </>
  )
}

import React, { useState } from 'react';

function CustomTextField6(props) {
    const { label, isRequired, readOnly,disabled, value, onChange } = props;

    const handleChange = (event) => {
        const value = event.target.value
        onChange(value);
    };

    return (
        <div className="flex flex-row gap-1">
            <label htmlFor="id" className=" flex items-center text-sm font-[400]">
                {label}:                
            </label>
            <input
                type={props.type ? props.type : "text"}
                required={isRequired}
                readOnly={readOnly}
                value={value}
                className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs  ${readOnly ? 'text-gray-500' : 'text-black'}`}
                id="id"
                placeholder={props.placeholder}
                disabled={disabled}
                onChange={handleChange}
            />
        </div>
    );
}

CustomTextField6.defaultProps = {
    isRequired: true,
    value: '',
    onChange: () => { },
};

export default CustomTextField6;

// CORE
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CSVLink } from 'react-csv';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';

// COMPONENTS
import PopUp from "../../general-components/PopUp.componenet";
import Title from "../../general-components/Title.component";
import { cryptData } from "../../general-components/cryptData";
import CustomButton6 from "../../general-components/CustomButton6.component";
import CustomButton7 from "../../general-components/CustomButton7.component";
import CustomButton1 from "../../general-components/CustomButton1.component";

// ICONS
import { IoMdAdd, IoMdArrowBack, IoMdRefresh } from "react-icons/io";
import { AiOutlineCheck, AiOutlineSearch } from "react-icons/ai";
import { GrPrevious, GrNext } from "react-icons/gr";
import { AiOutlineCopy } from "react-icons/ai";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";

import FeedBackAdd from "../../forms-components/FeedBack/FeedBackAdd";
import FeedBackEdit from "../../forms-components/FeedBack/FeedBackEdit";
import FeedBackStatus from "../../forms-components/FeedBack/FeedBackStatus";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TbArrowsMaximize } from "react-icons/tb";
import { Button } from "@mui/material";

const REACT_APP_LIVE_URL = "http://asarfisurvey.ddns.net";

export default function FeedBack() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const cId = searchParams.get("c");
    const fcId = searchParams.get("ct");
    console.log("url-param", cId, fcId);

    let [popupAdd, setPopupAdd] = useState(false);
    let [popupEdit, setPopupEdit] = useState(false);
    let [popupStatus, setPopupStatus] = useState(false);

    const [categoryData, setCategoryData] = useState([]);
    const [categoryId, setCategoryId] = useState(0);
    const [categoryName, setCategoryName] = useState("");
    const [categoryStatus, setCategoryStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null); // New state for selected role
    const [selectedFeedBack, setSelectedFeedBack] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const recordsPerPage = 10;
    const [permissions, setPermissions] = useState({
        canRead: false,
        canCreate: false,
        canUpdate: false,
    });

    let [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();

    const [searchRole, setSearchRole] = useState("");
    const [SearchDate, setSearchDate] = useState("");
    const [roleStatus, setRoleStatus] = useState("");
    const [roleStatus1, setRoleStatus1] = useState("");

    const token = localStorage.getItem('ASARFI_CRM_authToken');
    const userData = localStorage.getItem('ASARFI_CRM_userData');
    const parsedData = JSON.parse(userData);

    const [csvData, setCsvData] = useState([]);
    const csvLinkEl = useRef(null);

    const checkLoginStatus = async () => {
        const token = localStorage.getItem('ASARFI_CRM_authToken');

        try {
            const userResponse = await fetch(`${process.env.REACT_APP_MAIN_URL}/auth/isLogin`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (userResponse.status === 200) {
                const userData = await userResponse.json();
                if (userData && userData.isLoggedIn) {
                    setIsLoggedIn(true);
                    const rolePermissions = userData.user.currentRoutes
                        .find(route => route.cust_key === 'mm_feedback')
                        ?.routes.find(subRoute => subRoute.cust_key === 'mm_endusers_manage_feedback')
                        ?.custPermissions;

                    setPermissions({
                        canRead: rolePermissions?.canRead || false,
                        canCreate: rolePermissions?.canCreate || false,
                        canUpdate: rolePermissions?.canUpdate || false,
                    });
                    fetchFeedbacks();
                } else {
                    // Handle not logged in scenario
                    toast.error("User not logged in");
                }
            } else {
                // Handle error response from isLogin API
                toast.error("Failed to verify login status");
            }
        } catch (error) {
            toast.error("Error verifying login status");
        }
    };

    useEffect(() => {
        checkLoginStatus();
    }, [])

    const fetchFeedbacks = async (page, filters = {}) => {
        const loginResponse = await fetch(`${process.env.REACT_APP_MAIN_URL}/auth/isLogin`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!loginResponse.ok) {
            const loginResponseData = await loginResponse.json();
            console.error("Login check failed", loginResponseData.message);
            toast.error(loginResponseData.message || "Login check failed");
            setIsLoading(false);
            return;
        }

        const params = {
            page: page,
            sort: JSON.stringify({ "fc_created_at": "desc" }),
            filters: JSON.stringify({
                "current": page,
                "pageSize": recordsPerPage,
                ...filters
            }),
            c_id: parsedData?.c_id,
            c_id: cId,
            fc_id: fcId
        };

        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/manageendusers/feedback/feedback_crud/fetch_paginated`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                params
            });
            if (response.data && Array.isArray(response.data.data)) {
                setCategoryData(response.data.data);
                setTotalRecords(response.data.total); // Assuming the total number of records is returned
                console.log("Feedback data set successfully:", response.data.data);
            } else {
                console.log("Invalid response format", response.data);
            }
        } catch (error) {
            toast.error("Error fetching Feedback");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchFeedbacks(currentPage, {
            "fdq_title": searchRole,
            "fdq_status_view": roleStatus,
            // "fdq_isPublished_view": roleStatus1,
            // "fdq_created_at": SearchDate,
        });
    }, [currentPage]);

    const handleInquire = () => {
        fetchFeedbacks(1, {
            "fdq_title": searchRole,
            "fdq_status_view": roleStatus,
            // "fdq_isPublished_view": roleStatus1,
            // "fdq_created_at": SearchDate,
        });
        setCurrentPage(1);
    };

    const handleReset = () => {
        setSearchRole("");
        setSearchDate("");
        setRoleStatus("");
        setRoleStatus1("");
        fetchFeedbacks(1, {
            "fdq_title": "",
            "fdq_status_view": "",
            // "fdq_isPublished_view": "Active",
            // "fdq_created_at": "",
        });
        setCurrentPage(1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleNextPage = () => {
        const totalPages = Math.ceil(totalRecords / recordsPerPage);
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    const startRecord = (currentPage - 1) * recordsPerPage + 1;
    const endRecord = Math.min(currentPage * recordsPerPage, totalRecords);

    const handleManageAccessClickAdd = () => {
        navigate(`/admin/feedback-add?c=${cId}&ct=${fcId}`);
    };

    const handleManageAccessClick = () => {
        navigate(`/admin/feedback-edit`);
    };

    const handleManageAccess = () => {
        navigate(`/admin/manage-feedback`);
    };

    const handleEditButtonClick = async (c_id, fc_id, fdq_id) => {
        setIsLoading(true);
        try {
            const url = new URL(`${process.env.REACT_APP_MAIN_URL}/manageendusers/feedback/feedback_crud/fetch_by_id`);
            url.searchParams.append('c_id', c_id);
            url.searchParams.append('fc_id', fc_id);
            url.searchParams.append('fdq_id', fdq_id);

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            const responseData = await response.json();
            if (response.ok) {
                setSelectedFeedBack(responseData); // Ensure you set the actual response data
                navigate(`/admin/feedback-edit?c=${btoa(c_id)}&ct=${btoa(fc_id)}`, { state: { feedback: responseData } }); // Pass data through state
            } else {
                console.error("Feedback Fetch By Id failed", responseData.message);
                toast.error(responseData.message || "Feedback Fetch By Id failed");
            }
        } catch (error) {
            console.error("Error performing Export Feedback Fetch By Id operation:", error);
            toast.error("Error performing Export Feedback Fetch By Id operation");
        } finally {
            setIsLoading(false);
        }
    };

    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopyClick = (url, index) => {
        navigator.clipboard.writeText(url).then(() => {
            setCopiedIndex(index);
            setTimeout(() => {
                setCopiedIndex(null);
            }, 3000); // Change icon back after 2 seconds
        });
    };

    const handlePopup = (item) => {
        setSelectedItem(item);
        setPopupStatus(true);
    }

    useEffect(() => {
        if (csvData.length > 0 && csvLinkEl.current) {
            csvLinkEl.current.link.click();
        }
    }, [csvData]);

    const ExportFeedback = async (fdq_id, setCsvData, setIsLoading) => {
        setIsLoading(true);
        try {
            const url = new URL(`${process.env.REACT_APP_MAIN_URL}/manageendusers/feedback/feedback_crud/exportexcel`);
            url.searchParams.append('c_id', fdq_id);

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });

            const responseData = await response.json();
            if (response.ok) {
                let maxQuestions = 0;

                if (responseData.length > 0) {
                    // Determine the number of dynamic questions from the base64 encoded format
                    responseData.forEach((item) => {
                        const decodedData = JSON.parse(atob(item.fdq_qst_ans_json_format));
                        const numQuestions = decodedData.length;
                        maxQuestions = Math.max(maxQuestions, numQuestions);
                    });

                    // Process responseData to CSV format
                    const csvDataRep = responseData.map((item) => {
                        const data = {};
                        data['Mobile No'] = item.mobile_no;
                        data['Registration No'] = item.registration_no;

                        const decodedData = JSON.parse(atob(item.fdq_qst_ans_json_format));
                        decodedData.forEach((qa, index) => {
                            data[`Question${index + 1}`] = qa.q || '';
                            data[`Answer${index + 1}`] = qa.a || '';
                        });

                        for (let i = decodedData.length + 1; i <= maxQuestions; i++) {
                            data[`Question${i}`] = '';
                            data[`Answer${i}`] = '';
                        }

                        const createdAt = new Date(item.fdqa_created_at);
                        const formattedDate = `${createdAt.getDate()}/${createdAt.getMonth() + 1}/${createdAt.getFullYear()}`;
                        const formattedTime = `${createdAt.getHours()}:${createdAt.getMinutes() < 10 ? '0' : ''}${createdAt.getMinutes()}`;
                        data['Created At'] = `${formattedDate} ${formattedTime}`;
                        return data;
                    });

                    setCsvData(csvDataRep);
                } else {
                    // If responseData is empty, create an empty structure with dynamic question and answer columns
                    // Decode one item to get the structure
                    const dummyItem = {
                        fdq_qst_ans_json_format: 'W3sicW5vIjoxLCJxIjoiU29tZSBxdWVzdGlvbiIsImEiOnsifiI6IkFuc3dlciJ9LCJnaXZlbl9hbnN3ZXIiOiIiLCJxc3QiOiJxdWVzdGlvbiBzdGF0dXMiLCJhbnN3ZXJfc3RhdHVzIjoiIn1d' // replace with actual encoded example
                    };
                    const decodedData = JSON.parse(atob(dummyItem.fdq_qst_ans_json_format));
                    const numQuestions = decodedData.length;
                    maxQuestions = Math.max(maxQuestions, numQuestions);

                    const emptyData = {
                        'Mobile No': '',
                        'Registration No': '',
                        'Created At': ''
                    };
                    for (let i = 1; i <= maxQuestions; i++) {
                        emptyData[`Question${i}`] = '';
                        emptyData[`Answer${i}`] = '';
                    }

                    setCsvData([emptyData]);
                }
            } else {
                console.error("Feedback export failed", responseData.message);
                toast.error(responseData.message || "Feedback export failed");
            }
        } catch (error) {
            console.error("Error performing Export Feedback operation:", error);
            toast.error("Error performing Export Feedback operation");
        } finally {
            setIsLoading(false);
        }
    };

    const handleRefresh = () => {
        window.location.reload();
    }

    const handleMaximize = () => {
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { // Firefox
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { // IE/Edge
            elem.msRequestFullscreen();
        }
    }

    const userName = localStorage.getItem('ASARFI_CRM_username');

    return (
        <>


            <div className="flex flex-col h-full ">
                <div className="bg-slate-100 rounded grow" >
                    <div className="bg-white">
                        <Title title1={"Mange Feedback"} />
                    </div>


                    <div className="px-5 mb-4 mt-5 relative">
                        <div className="watermark-container  absolute inset-0 pointer-events-none opacity-20 grid grid-cols-5 gap-4 p-10" >
                            {Array.from({ length: 4 }).map((_, i) => (
                                <h1 key={i} className="watermark-text text-sm font-bold text-gray-400 rotate-[-30deg] flex-wrap">{userName}</h1>
                            ))}
                        </div>
                        <div className="p-2 px-6 bg-white flex items-center justify-between gap-2">
                            <div>
                                <div className="flex flex-row gap-1">
                                    <label htmlFor="id" className=" flex items-center text-sm font-[400]">
                                        Title:
                                    </label>
                                    <input
                                        type={"text"}
                                        className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                                        id="id"
                                        placeholder={"Please Enter"}
                                        value={searchRole}
                                        onChange={(e) => setSearchRole(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center gap-2">
                                    <label id="">Status :</label>

                                    <div className="flex items-center">
                                        <input
                                            id="status-active"
                                            type="radio"
                                            value="Active"
                                            name="status"
                                            className="w-4 h-4 text-red-500 bg-gray-100 border-gray-300"
                                            checked={roleStatus === "Active"}
                                            onChange={(e) => setRoleStatus(e.target.value)}
                                        />
                                        <label htmlFor="status-active" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Active
                                        </label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            id="status-inactive"
                                            type="radio"
                                            value="Inactive"
                                            name="status"
                                            className="w-4 h-4 text-red-500 bg-gray-100 border-gray-300"
                                            checked={roleStatus === "Inactive"}
                                            onChange={(e) => setRoleStatus(e.target.value)}
                                        />
                                        <label htmlFor="status-inactive" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Inactive
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <div className="flex items-center gap-2">
                                    <label id="">Status :</label>

                                    <div className="flex items-center">
                                        <input
                                            id="status-active"
                                            type="radio"
                                            value="Active"
                                            name="status"
                                            className="w-4 h-4 text-red-500 bg-gray-100 border-gray-300"
                                            checked={roleStatus1 === "Active"}
                                            onChange={(e) => setRoleStatus1(e.target.value)}
                                        />
                                        <label htmlFor="status-active" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Active
                                        </label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            id="status-inactive"
                                            type="radio"
                                            value="Inactive"
                                            name="status"
                                            className="w-4 h-4 text-red-500 bg-gray-100 border-gray-300"
                                            checked={roleStatus1 === "Inactive"}
                                            onChange={(e) => setRoleStatus1(e.target.value)}
                                        />
                                        <label htmlFor="status-inactive" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            Inactive
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                            <div className="flex gap-5 ">
                                <div>
                                    <CustomButton7 label={"Reset"} variant="outlined" className="hover:text-red-500 text-black" onClick={handleReset} />
                                </div>
                                <div>
                                    <CustomButton6 label={"Inquire"} className="text-white bg-first" type="submit" disabled={isLoading} onClick={handleInquire} />
                                </div>
                            </div>
                        </div>
                        {/* <div className="p-2 px-6 bg-white flex items-center justify-between gap-2">
                            <div>
                                <div className="flex flex-row gap-1">
                                    <label htmlFor="created-at" className=" flex items-center text-sm font-[400]">
                                        Created At:
                                    </label>
                                    <input
                                        type={"datetime-local"}
                                        className={`p-2 border rounded grow min-w-[20rem] bg-white text-xs placeholder:text-xs text-black`}
                                        id="created-at"
                                        placeholder={"Please Enter"}
                                        value={SearchDate}
                                        onChange={(e) => setSearchDate(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-5 ">
                                <div>
                                    <CustomButton7 label={"Reset"} variant="outlined" className="hover:text-red-500 text-black" onClick={handleReset} />
                                </div>
                                <div>
                                    <CustomButton6 label={"Inquire"} className="text-white bg-first" type="submit" disabled={isLoading} onClick={handleInquire} />
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className=" my-5 relative">
                    <div className="watermark-container flex items-center absolute inset-0 pointer-events-none opacity-20 grid grid-cols-5 gap-4 p-10" >
                    {Array.from({ length: 8 }).map((_, i) => (
                        <h1 key={i} className="watermark-text text-sm font-bold text-gray-400 rotate-[-30deg] flex-wrap">{userName}</h1>
                    ))}
                </div>
                        <div className="px-5 ">
                            <div className="flex flex-col p-2  bg-white  ">
                                <div className="flex flex-row flex-wrap max-w-[100%] w-[98%] mt-2 mx-auto justify-end gap-2 bg-white md:flex-nowrap ">
                                    {permissions.canCreate && (
                                        <CustomButton6
                                            label={"Add New Record"}
                                            icon={<IoMdAdd />}
                                            className="bg-first text-white shrink grow md:grow-0 max-w-[50%]"
                                            onClick={handleManageAccessClickAdd}
                                        />
                                    )}
                                    <CustomButton7
                                        label={"Back"}
                                        variant="outlined"
                                        icon={<IoMdArrowBack />}
                                        className="hover:text-red-500 text-black"
                                        onClick={handleManageAccess}
                                    />

                                    <div onClick={handleRefresh} className=" hover:text-red-500 text-2xl flex items-center font-light">
                                        <Tooltip title="Refresh" placement="top" arrow>
                                            <p><IoMdRefresh /></p>
                                        </Tooltip>
                                    </div>
                                    <div onClick={handleMaximize} className=" hover:text-red-500 text-2xl flex items-center font-light pe-1">
                                        <Tooltip title="Full Screen" placement="top" arrow>
                                            <p><TbArrowsMaximize /></p>
                                        </Tooltip>
                                    </div>
                                </div>
                                {permissions.canRead && (
                                    <>
                                        {/* TABLE */}
                                        <div className=" max-w-[100%] w-[98%] mt-5 mx-auto hide-scrollbar overflow-auto table-container">
                                            <table className="w-full custom-table bg-white relative" border={1}>
                                                <thead>
                                                    <tr className="table-heading">
                                                        <td className="">#</td>
                                                        <td>Title</td>
                                                        <td className="flex items-center justify-center gap-1 border-b-0">
                                                            Link<span className="flex items-end font-bold text-[17px]"><AiOutlineInfoCircle /></span>
                                                        </td>
                                                        <td>Status</td>
                                                        <td>Status</td>
                                                        <td>Created At</td>
                                                        <td>Status</td>
                                                        <td>Created By</td>
                                                        {permissions.canUpdate && (<td>Action</td>)}
                                                        <td>Excel</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading ? (
                                                        <tr>
                                                            <td colSpan="10" className="text-center">Loading...</td>
                                                        </tr>
                                                    ) : (
                                                        categoryData.map((data, index) => {
                                                            // Construct the URL here
                                                            const baseUrl = `${REACT_APP_LIVE_URL}/services/feedback/${data.c_name.replace(/\s/g, '')}/${data.fc_name.replace(/\s/g, '')}/${data.fdq_title.replace(/\s/g, '')}`;
                                                            const url = `${baseUrl}?c=${cryptData('enc', data.c_id)}&ct=${cryptData('enc', data.fc_id)}&f=${cryptData('enc', data.fdq_id)}`;

                                                            return (
                                                                <tr key={data.id}>
                                                                    <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>

                                                                    <td>{data.fdq_title}</td>
                                                                    <td >
                                                                        <div className="flex items-center ">
                                                                            <p className={`font-light ${data.fdq_status === 1 ? 'bg-yellow-300 text-black' : 'text-gray-400'}`}>
                                                                                {url}
                                                                            </p>
                                                                            {data.fdq_status === 1 && (
                                                                                <span
                                                                                    className="cursor-pointer flex"
                                                                                    onClick={() => handleCopyClick(url, index)}
                                                                                >
                                                                                    {copiedIndex === index ?
                                                                                        <div className="text-green-500 ps-0.5 relative text-lg">
                                                                                            <Tooltip title="Copied !" placement="top" arrow>
                                                                                                <button><AiOutlineCheck /></button>
                                                                                            </Tooltip>
                                                                                        </div> :
                                                                                        <div className="text-red-500 ps-0.5 relative text-lg">
                                                                                            <Tooltip title="Copy" placement="top" arrow>
                                                                                                <button><AiOutlineCopy /></button>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td>{data.fdq_status_view}</td>
                                                                    <td>{data.fdq_isPublished_view}</td>
                                                                    <td>{data.fdq_created_at}</td>
                                                                    <td>
                                                                        <div className="relative">
                                                                            <div onClick={() => handlePopup(data)}>
                                                                                <h2 className="cursor-pointer underline" style={{ color: data.fdq_status === 1 ? 'green' : 'red' }}>
                                                                                    {data.fdq_status === 1 ? 'Activate' : 'Deactivate'}
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{data.fullname ? data.fullname : "-"}</td>
                                                                    {permissions.canUpdate && (
                                                                        <td className="">
                                                                            <div>
                                                                                <Button
                                                                                    className="text-red-500 py-0 border border-red-500 bg-white capitalize font-semibold w-[80px]"
                                                                                    type="submit"
                                                                                    onClick={() => handleEditButtonClick(data.c_id, data.fc_id, data.fdq_id)}
                                                                                    variant="outlined"
                                                                                    size={"small"}
                                                                                >
                                                                                    Edit
                                                                                </Button>
                                                                            </div>
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <div onClick={() => ExportFeedback(data.fdq_id, setCsvData, setIsLoading)}>
                                                                            <CustomButton1 label={"Export"} className="text-white bg-red-500 text-xs" />
                                                                        </div>
                                                                        <CSVLink filename={'feedback_data.csv'} data={csvData} ref={csvLinkEl} />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="flex justify-end items-center gap-2 mt-4 mx-auto max-w-[100%] w-[98%] my-5">
                                            {/* Display Records Info */}
                                            <div className="font-light text-sm">
                                                {startRecord} - {endRecord} of {totalRecords} items
                                            </div>

                                            {/* Pagination Controls */}
                                            <div className="flex justify-between items-center">
                                                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                                                    <GrPrevious />
                                                </button>
                                                <span>
                                                    <div className="flex">
                                                        {Array.from({ length: totalPages }, (_, index) => index + 1).map(pageNumber => (
                                                            <button
                                                                key={pageNumber}
                                                                className={`px-2.5 py-0.5 mx-1 ${currentPage === pageNumber ? 'border border-red-500 text-red-500 bg-white' : 'bg-gray-200'}`}
                                                                onClick={() => handlePageChange(pageNumber)}
                                                            >
                                                                {pageNumber}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </span>
                                                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                                                    <GrNext style={{ color: '#6b7280' }} />
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {popupAdd && (
                <PopUp>
                    <FeedBackAdd
                        onCancel={() => setPopupAdd(false)}
                    />
                </PopUp>
            )}

            {popupEdit && (
                // <PopUp>
                <FeedBackEdit
                    categoryId={categoryId}
                    categoryName={categoryName}
                    setCategoryName={setCategoryName}
                    categoryStatus={categoryStatus}
                    setCategoryStatus={setCategoryStatus}
                    selectedCategory={selectedCategory}
                    onCancel={() => setPopupEdit(false)}
                />
                // </PopUp>
            )}

            {popupStatus && selectedItem && (
                <PopUp>
                    <FeedBackStatus
                        selectedItem={selectedItem}
                        onCancel={() => {
                            setPopupStatus(false)
                            fetchFeedbacks();
                        }}
                    />
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    )
}

import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import CustomButton1 from '../../general-components/CustomButton1.component';
import CustomButton2 from '../../general-components/CustomButton2.component';
import ToolTipY from '../../general-components/ToolTipY.component';

import { BsQuestionCircle, BsFillExclamationCircleFill } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoIosArrowDown } from 'react-icons/io';
import { GrFormNext } from 'react-icons/gr';
import { AiOutlineEnter } from "react-icons/ai";
import noData from "../../../assets/noData.svg"
import "../../../../index.css";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SingleQuestion from '../FeedBackEdit/SingleQuestion';
import CommentBoxQuestion from '../FeedBackEdit/CommentBoxQuestion';
import EmojiQuestion from '../FeedBackEdit/EmojiQuestion';

const decodeBase64 = (base64Str) => {
    const decodedStr = atob(base64Str); // Decode base64 to string
    return parseInt(decodedStr, 10); // Convert to number
};

export default function FeedBackEdit() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const cIdEncoded = searchParams.get("c");
    const fcIdEncoded = searchParams.get("ct");
    const feedbackData = location.state?.feedback;

    const [feedback, setFeedback] = useState(null);
    const [fdqId, setFdqId] = useState(null);
    const [questions, setQuestions] = useState([]);

    const [questionCount, setQuestionCount] = useState(0);
    const navigate = useNavigate();
    const [isOpen1, setIsOpen1] = useState(true);
    const [isOpen2, setIsOpen2] = useState(false);
    const inputRef = useRef(null); // Create a ref for the input
    const [isLoading, setIsLoading] = useState(false); // Added isLoading state

    const [title, setTitle] = useState('Title');
    const [description, setDescription] = useState('Edit Description');
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkLoginStatus = async () => {
        const token = localStorage.getItem('ASARFI_CRM_authToken');

        try {
            const userResponse = await fetch(`${process.env.REACT_APP_MAIN_URL}/auth/isLogin`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (userResponse.status === 200) {
                const userData = await userResponse.json();
                if (userData && userData.isLoggedIn) {
                    setIsLoggedIn(true);
                } else {
                    // Handle not logged in scenario
                    toast.error("User not logged in");
                }
            } else {
                // Handle error response from isLogin API
                toast.error("Failed to verify login status");
            }
        } catch (error) {
            toast.error("Error verifying login status");
        }
    };

    useEffect(() => {
        checkLoginStatus();
    }, [])


    useEffect(() => {
        if (feedbackData) {
            setFdqId(feedbackData.fdq_id);
            setTitle(feedbackData.fdq_title);
            setDescription(feedbackData.fdq_description);
            try {
                const parsedQuestions = JSON.parse(feedbackData.fdq_qst_ans_json_format);
                if (Array.isArray(parsedQuestions)) {
                    const initialQuestions = parsedQuestions.map((q, index) => ({
                        ...q,
                        id: index,
                        questionText: q.q,
                        options: Object.values(q.a || {}),
                        answerText: q.given_answer || '',
                    }));
                    setQuestions(initialQuestions);
                } else {
                    throw new Error('Parsed data is not an array');
                }
            } catch (error) {
                console.error('Failed to parse questions data:', error);
                toast.error('Failed to load questions data');
            }
        }
    }, [feedbackData]);


    useEffect(() => {
        if (feedbackData) {
            setFeedback(feedbackData);
        }
    }, [feedbackData])

    // Decode base64 parameters
    const cId = decodeBase64(cIdEncoded);
    const fcId = decodeBase64(fcIdEncoded);
    console.log("url-param", cId, fcId);

    const handleTitleClick = () => {
        setIsEditingTitle(true);
    };

    const handleDescriptionClick = () => {
        setIsEditingDescription(true);
    };

    const handleInputChange = (event) => {
        const value = Number(event.target.value);
        setQuestionCount(value);
    };

    const toggleAccordion1 = () => {
        setIsOpen1(!isOpen1);
    };

    const toggleAccordion2 = () => {
        setIsOpen2(!isOpen2);
    };

    const countQuestionsByType = (type) => {
        return questions.filter(question => question.type === type).length;
    };

    const handleAddQuestions = (type) => {
        const existingCount = countQuestionsByType(type);
        const totalQuestions = existingCount + questionCount;

        if (totalQuestions > 20) {
            toast.error(`Cannot add more than 20 ${type} questions. You can add ${20 - existingCount} more.`);
            return;
        }

        const newQuestions = Array(questionCount).fill().map((_, index) => ({
            id: uuidv4(),
            type,
            questionText: 'Type Question',
            options: type === 'single' ? ['Option 1', 'Option 2', 'Option 3'] : []
        }));
        setQuestions([...questions, ...newQuestions]);
        inputRef.current.value = '';
        setQuestionCount(0);
    };

    const handleDeleteQuestion = (id) => {
        setQuestions(questions.filter(question => question.id !== id));
    };

    const handleQuestionChange = (id, questionText, options = []) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
                q.id === id ? { ...q, questionText, options } : q
            )
        );
    };

    const handleBack = () => {
        navigate(`/admin/feedback?c=${cIdEncoded}&ct=${fcIdEncoded}`)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const token = localStorage.getItem('ASARFI_CRM_authToken');
            const userData = localStorage.getItem('ASARFI_CRM_userData');
            const parsedData = JSON.parse(userData);

            const loginResponse = await fetch(`${process.env.REACT_APP_MAIN_URL}/auth/isLogin`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!loginResponse.ok) {
                const loginResponseData = await loginResponse.json();
                console.error("Login check failed", loginResponseData.message);
                toast.error(loginResponseData.message || "Login check failed");
                setIsLoading(false);
                return;
            }

            const formattedQuestions = questions.map((question, index) => {
                if (question.type === 'single') {
                    return {
                        type: 'single',
                        uuid: question.id,
                        qno: index + 1,
                        q: question.questionText,
                        a: { a: question.options[0], b: question.options[1], c: question.options[2] },
                        given_answer: ""
                    };
                } else if (question.type === 'comment') {
                    return {
                        type: 'comment',
                        uuid: question.id,
                        qno: index + 1,
                        q: question.questionText,
                        a: "",
                        given_answer: ""
                    };
                } else if (question.type === 'emoji') {
                    return {
                        type: 'emoji_rating',
                        uuid: question.id,
                        qno: index + 1,
                        q: question.questionText,
                        given_answer: ""
                    };
                } else {
                    return null;
                }
            });

            const form = new FormData();
            form.append('_method', 'put');
            form.append('c_id', cId);
            form.append('fc_id', fcId);
            form.append('fdq_title', isEditingTitle ? isEditingTitle : title);
            form.append('fdq_description', isEditingDescription ? isEditingDescription : description);
            form.append('fdq_qst_ans_json_format', JSON.stringify(formattedQuestions));
            form.append('fdq_status', '1');
            form.append('fdq_isPublished', '1');

            const endpoint = fdqId ? `${process.env.REACT_APP_MAIN_URL}/manageendusers/feedback/feedback_crud/update` : `${process.env.REACT_APP_MAIN_URL}/manageendusers/feedback/feedback_crud/create`;
            if (fdqId) form.append('fdq_id', fdqId); // Include ID only if updating

            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: form
            });

            const responseData = await response.json();
            if (response.ok) {
                if (responseData.message === "Record is already exists") {
                    toast.error("Duplicate entry is not permissible");
                } else {
                    toast.success("'Feedback has been Updated! Redirecting in 3 seconds'");
                }

                setTimeout(() => {
                    navigate(`/admin/feedback?c=${cIdEncoded}&ct=${fcIdEncoded}`);
                }, 3000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing Feedback Create operation:", error);
            toast.error("Error performing Feedback Create operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="flex flex-col h-full ">
                <div className="bg-slate-100 rounded grow" >
                    <div className='flex gap-5 max-w-full w-full'>
                        <div className='w-[25%] p-2 rounded-lg'>
                            <div className="p-2 ">
                                <div
                                    className="flex p-2 py-5 border-b-2 justify-between border border-gray-300 bg-gray-50 cursor-pointer"
                                >
                                    <div className='flex gap-1 items-center'>
                                        <span className='text-sky-500'><BsQuestionCircle /> </span>   Create Feedback
                                    </div>
                                    <div onClick={toggleAccordion1}>
                                        {isOpen1 ? <IoIosArrowDown className='text-[1.1rem]' /> : <GrFormNext className=' text-[1.3rem]' />}
                                    </div>
                                </div>
                                {isOpen1 &&
                                    (
                                        <table className='w-full text-sm p-2 border border-gray-300 bg-white'>
                                            <tbody>
                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <td className="py-4 px-6 text-red-500">
                                                        <div class="relative flex flex-col group">
                                                            <div className="text-red-500 cursor-pointer font-light">Single Choice Question</div>
                                                            <div class="absolute right-[-44px] top-[-50px] flex flex-col items-center hidden mb-5 group-hover:flex transition-all duration-300 ease-in-out">
                                                                <div className="flex flex-col gap-2 p-2 overflow-hidden rounded whitespace-nowrap bg-white shadow-xl">
                                                                    <div className="py-1 px-2 flex justify-start items-center gap-2 border-b-2">
                                                                        No of Question ?
                                                                    </div>
                                                                    <div className="flex flex-col justify-center gap-2 px-2">
                                                                        <div>
                                                                            <input
                                                                                type='text'
                                                                                required
                                                                                ref={inputRef} // Attach the ref to the input

                                                                                onChange={handleInputChange}
                                                                                className="p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs text-black"
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                                variant="contained"
                                                                                size="small"
                                                                                className="flex gap-2 justify-center items-center relative bg-red-500"
                                                                                type="button"
                                                                                onClick={() => handleAddQuestions('single')}
                                                                            >
                                                                                <span className="whitespace-nowrap font-[400] font-Mitr text-white"> Add </span>
                                                                                <span className="text-sm"><FaLongArrowAltRight /></span>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <td className="py-4 px-6 text-red-500">
                                                        <div class="relative flex flex-col group">
                                                            <div className="text-red-500 cursor-pointer font-light">Comment Box Question</div>
                                                            <div class="absolute right-[-44px] top-[-50px] flex flex-col items-center hidden mb-5 group-hover:flex transition-all duration-300 ease-in-out">
                                                                <div className="flex flex-col gap-2 p-2 overflow-hidden rounded whitespace-nowrap bg-white shadow-xl ">
                                                                    <div className="py-1 px-2 flex justify-start items-center gap-2 border-b-2">
                                                                        No of Question ?
                                                                    </div>
                                                                    <div className="flex flex-col justify-center gap-2 px-2">
                                                                        <div>
                                                                            <input
                                                                                type='text'
                                                                                required
                                                                                ref={inputRef} // Attach the ref to the input
                                                                                onChange={handleInputChange}
                                                                                className={"p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs  text-black"}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                                variant={"contained"}
                                                                                size={"small"}
                                                                                className={`flex gap-2 justify-center items-center relative  bg-red-500`}
                                                                                type={"button"}
                                                                                onClick={() => handleAddQuestions('comment')}
                                                                            >
                                                                                <span className=" whitespace-nowrap font-[400] font-Mitr text-white"> Add </span>
                                                                                <span className="text-sm"><FaLongArrowAltRight /></span>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <td className="py-4 px-6 text-red-500">
                                                        <div class="relative flex flex-col group">
                                                            <div className="text-red-500 cursor-pointer font-light">Emoji Rating Question</div>
                                                            <div class="absolute right-[-44px] top-[-50px] flex flex-col items-center hidden mb-5 group-hover:flex transition-all duration-300 ease-in-out">
                                                                <div className="flex flex-col gap-2 p-2 overflow-hidden rounded whitespace-nowrap bg-white shadow-xl">
                                                                    <div className="py-1 px-2 flex justify-start items-center gap-2 border-b-2">
                                                                        No of Question ?
                                                                    </div>
                                                                    <div className="flex flex-col justify-center gap-2 px-2">
                                                                        <div>
                                                                            <input
                                                                                type='text'
                                                                                required
                                                                                ref={inputRef} // Attach the ref to the input
                                                                                onChange={handleInputChange}
                                                                                className={"p-2 border rounded grow min-w-[14rem] bg-white text-xs placeholder:text-xs  text-black"}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <Button
                                                                                variant={"contained"}
                                                                                size={"small"}
                                                                                className={`flex gap-2 justify-center items-center relative  bg-red-500`}
                                                                                type={"button"}
                                                                                onClick={() => handleAddQuestions('emoji')}
                                                                            >
                                                                                <span className=" whitespace-nowrap font-[400] font-Mitr text-white"> Add </span>
                                                                                <span className="text-sm"><FaLongArrowAltRight /></span>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}
                            </div>
                        </div>

                        <div className='w-[75%] p-2 rounded-lg'>
                            <div className='p-2'>
                                <div className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                                    <div className="text-gray-700  bg-white border-[3px] border-dotted border-blue-500">
                                        <div className='flex justify-between items-center border-b'>
                                            <div className="py-4 px-6 flex justify-start items-center gap-2 text-xl">
                                                {isEditingTitle ? (
                                                    <div className='border border-red-500  py-1 px-2 flex justify-between items-center'>
                                                        <input
                                                            type="text"
                                                            value={title}
                                                            onChange={(e) => setTitle(e.target.value)}
                                                            onBlur={() => setIsEditingTitle(false)}
                                                            autoFocus
                                                            className="text-sm font-light"
                                                        />
                                                        <span className='text-gray-500'><AiOutlineEnter /></span>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <span onClick={handleTitleClick}>{title}</span>
                                                        <span className='text-red-500 cursor-pointer' onClick={handleTitleClick}> <CiEdit /></span>
                                                    </>
                                                )}
                                            </div>
                                            <div className="py-4 px-6 flex justify-start items-center gap-2">
                                                <div className='text-sm font-light'>
                                                    Question : {questions.length}
                                                </div>
                                                <div onClick={handleBack}>
                                                    <Button
                                                        // variant={"outlined"}
                                                        size={"small"}
                                                        className={`flex py-0 justify-center items-center relative  bg-white  border border-dashed border-red-500`}
                                                        type={"button"}
                                                    >
                                                        <span className=" whitespace-nowrap font-[400] font-Mitr text-red-500 capitalize">
                                                            Cancel
                                                        </span>
                                                    </Button>
                                                </div>
                                                <div>
                                                    <ToolTipY
                                                        title={
                                                            <div className="flex items-center gap-2 border-0 border-b rounded-l-full press-1" >
                                                                <Button
                                                                    size={"small"}
                                                                    className={`flex py-0.5 gap-2 justify-center items-center relative  bg-red-500 `}
                                                                    type={"button"}
                                                                >
                                                                    <span className=" whitespace-nowrap font-[400] font-Mitr text-white capitalize">
                                                                        Update & close
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                        }
                                                        content={
                                                            <>
                                                                <div className="flex flex-col gap-2 p-2 overflow-hidden rounded whitespace-nowrap">
                                                                    <div className="py-1 px-2 flex justify-start items-center gap-2">
                                                                        <span className='text-yellow-500'> <BsFillExclamationCircleFill /></span>
                                                                        Are You Sure ?
                                                                    </div>
                                                                    <div className="flex justify-center gap-2">
                                                                        <div>
                                                                            <CustomButton2 label={"No"} variant="outlined" className="text-red-500 text-xs" />
                                                                        </div>
                                                                        <div onClick={handleSubmit}>
                                                                            <CustomButton1 label={"Yes"} className="text-white bg-red-500 text-xs" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        height="80px"
                                                        direction="down"
                                                        position="right"
                                                    ></ToolTipY>
                                                </div>

                                                <div onClick={toggleAccordion2}>
                                                    {isOpen2 ? <IoIosArrowDown className='text-[1.1rem]' /> : <GrFormNext className=' text-[1.3rem]' />}
                                                </div>
                                            </div>
                                        </div>
                                        {isOpen2 &&
                                            (
                                                <div className="py-4 px-6 flex justify-start items-center gap-2 font-light">


                                                    {isEditingDescription ? (
                                                        <div className='border border-red-500 w-full  py-1 px-2 flex justify-between items-center'>
                                                            <input
                                                                type="text"
                                                                value={description}
                                                                onChange={(e) => setDescription(e.target.value)}
                                                                onBlur={() => setIsEditingDescription(false)}
                                                                autoFocus
                                                                className="text-sm font-light"
                                                            />
                                                            <span className='text-gray-500'><AiOutlineEnter /></span>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <span onClick={handleDescriptionClick}>{description}</span>
                                                            <span className='text-red-500 cursor-pointer' onClick={handleDescriptionClick}> <CiEdit /></span>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                    <div className='border bg-white'>
                                        {questions.length === 0 ? (
                                            <div >
                                                <div className='flex flex-col justify-center items-center py-5' >
                                                    <img src={noData} alt="No Data" className='object-cover w-[8rem]' />
                                                    <p className='text-black font-light'>No Data</p>
                                                </div>
                                            </div>
                                        ) : (
                                            questions.map((question, index) => {
                                                const { id, type } = question;
                                                if (type === 'single') return <SingleQuestion key={id} id={id} index={index} onDelete={handleDeleteQuestion} onQuestionChange={handleQuestionChange} initialQuestion={question} />;
                                                if (type === 'comment') return <CommentBoxQuestion key={id} id={id} index={index} onDelete={handleDeleteQuestion} onQuestionChange={handleQuestionChange} initialQuestion={question} />;
                                                if (type === 'emoji') return <EmojiQuestion key={id} id={id} index={index} onDelete={handleDeleteQuestion} onQuestionChange={handleQuestionChange} initialQuestion={question} />;
                                                return null;
                                            })
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={3000} />
        </>
    );
}
